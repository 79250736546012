/**************************************************
 * Nombre:       Formulario_Anuncio
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid} from "@mui/material";
import {
    FormBase,
} from "../../Modulo_Formularios/ComponentesBase/C_Forms";
import {subirUnaImagenCroper} from "../../Servicios/Storage/subirUnaImagenCroper";
import {Message, Whatsapp} from "iconsax-react";


const Formulario_Anuncio = ({...props}) => {

    return (
        <FormBase {...props} >
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={4}
            >

                <Grid item lg={8} sm={12} xs={12}>
                    <FormBase.ImagenDimencion dato={"img"} carpeta={"anuncios"} funcion={subirUnaImagenCroper}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon label={'Numero WhatsApp'} Icono={Whatsapp} type={'number'} dato={'numero'}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon label={'Mensaje predeterminado'} Icono={Message} lineas={2} dato={'mensaje'}/>
                </Grid>


            </Grid>
        </FormBase>
    )
}

export default Formulario_Anuncio;