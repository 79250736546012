/**************************************************
 * Nombre:       Superior
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useEffect} from 'react';
import {ButtonBase, Grid, Typography, useMediaQuery} from "@mui/material";
import {useNavigate} from 'react-router-dom'
import {COLORACENTO, secciones} from "../../../Constantes";
import AOS from "aos";
import {theme} from "../../../Tema";


const Inferior = () => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const masPC = useMediaQuery(theme.breakpoints.down("md"))
    const navigate = useNavigate()


    useEffect(() => {
        AOS.init({
            offset: 300,
        });
        AOS.refresh();
    }, []);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
        >

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "flex-end"}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                >


                    <Grid item container lg={10} sm={12} xs={12}
                          sx={{justifyContent: "flex-end"}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                        >


                            {secciones.map((seccion, index) => {
                                return (
                                    <Grid
                                        component={"div"}
                                        data-aos="fade-up"
                                        data-aos-delay={100 * index}
                                        data-aos-duration="1500"
                                        item container lg={2} sm={2} xs={12} sx={{justifyContent: "center"}}
                                        key={index}>

                                        <ButtonBase
                                            variant={"nosombra"}
                                            onClick={() => navigate("/" + seccion.nombre)}
                                            sx={{paddingRight: seccion.nombre === "Contactos" ? 5 : 0}}
                                        >
                                            <Typography sx={{
                                                fontFamily: "Passion One",
                                                fontSize: masSM ? 28 : 22,
                                                color: COLORACENTO
                                            }}>  {seccion.nombre}</Typography>

                                        </ButtonBase>
                                    </Grid>
                                )
                            })}


                        </Grid>
                    </Grid>


                </Grid>
            </Grid>


        </Grid>
    )
}

export default Inferior;

