/**************************************************
 * Nombre:       TarjetasContacto
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid, useMediaQuery} from "@mui/material";
import Tarjeta_Contacto from "../../Tarjetas/Tarjeta_Contacto";
import {theme} from "../../../Tema";


const TarjetasContacto = ({contactos = []}) => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))


    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={masSM ? 10 : 4}
        >


            {contactos.map((item, index) => {
                return (
                    <Grid
                        key={index}
                        component={"div"}
                        data-aos="fade-up"
                        data-aos-offset={0}
                        data-aos-delay={100 * index}
                        data-aos-duration="1500"
                        item lg={6} sm={6} xs={12}>
                        <Tarjeta_Contacto item={item}/>
                    </Grid>
                )
            })}

        </Grid>
    )
}

export default TarjetasContacto;