/**************************************************
 * Nombre:       Tarjeta_Asesores
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Avatar, ButtonBase, Grid} from "@mui/material";
import {CardBase, Imagen} from "../../Modulo_Tarjetas/Componentes/C_Cards";
import {User, Whatsapp} from "iconsax-react";


const Tarjeta_Asesores = ({valores, click}) => {

    return (
        <ButtonBase
            onClick={() => click(valores)}
            sx={{width: '100%', textAlign: 'left', borderRadius: 2, '&:hover': {boxShadow: 8}}}>
            <CardBase valores={valores}>

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                >

                    <Grid item lg={2} sm={2} xs={2}>
                        <Avatar src={valores.img}/>
                    </Grid>

                    <Grid item lg={7} sm={7} xs={10}>
                        <CardBase.IconTexto Icono={User} dato1={'nombre'}/>
                    </Grid>

                    <Grid item lg={3} sm={3} xs={12}>
                        <CardBase.IconTexto Icono={Whatsapp} dato1={'numero'}/>
                    </Grid>


                </Grid>

            </CardBase>
        </ButtonBase>
    )
}

export default Tarjeta_Asesores;