/**************************************************
 * Nombre:       Productos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useEffect, useState} from 'react';
import {Grid, Typography, useMediaQuery} from "@mui/material";
import CeparadorTitulo from "./Componentes/CeparadorTitulo";
import Catalogo from "./Componentes/Catalogo";
import Testimonios from "./Componentes/Testimonios";
import {useParams} from 'react-router-dom'
import {irArriba} from "../../Utilidades/irArriba";
import AOS from "aos";
import {theme} from "../../Tema";
import {Helmet} from "react-helmet";

const Productos = () => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const parms = useParams()
    const [categoria, setCategoira] = useState("Procesados")


    useEffect(() => {
        irArriba()
        if (parms.id) {
            setCategoira(parms.id)
        }
    }, [parms])

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >


            <Helmet>
                <meta charSet="utf-8"/>
                <title>Pollos Aretama | Productos</title>
                <link rel="canonical" href="https://aretama.com/"/>
            </Helmet>

            <Typography component={'h1'} sx={{display: 'none'}}>Productos de Pollo</Typography>
            <Typography component={'h2'} sx={{display: 'none'}}>Venta de pollo crudo</Typography>
            <Typography component={'h2'} sx={{display: 'none'}}>Pollo entero</Typography>
            <Typography component={'h2'} sx={{display: 'none'}}>Pollo al por mayor</Typography>
            <Typography component={'h2'} sx={{display: 'none'}}>Pollo en presas</Typography>
            <Typography component={'h2'} sx={{display: 'none'}}>Pollo crudo</Typography>

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{maxWidth: "1400px", px: 3}}
            >


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                    <CeparadorTitulo titulo={"PRODUCTOS"}/>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: sCell ? 6 : 10}}>
                    <Catalogo escogido={categoria} setEscogido={setCategoira}/>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 8}}>
                    <Testimonios/>
                </Grid>

            </Grid>

        </Grid>
    )
}

export default Productos;

