/**************************************************
 * Nombre:       Home
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid, Typography, useMediaQuery,} from "@mui/material";
import TituloImagen from "./Componentes/TituloImagen";
import Categorias from "./Componentes/Categorias";
import Nosotros from "./Componentes/Nosotros";
import MantenteInformado from "./Componentes/MantenteInformado";
import Testimonios from "./Componentes/Testimonios";
import {theme} from "../../Tema";
import {Helmet} from "react-helmet";


const Home = () => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))


    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Helmet>
                <meta charSet="utf-8"/>
                <title>Pollos Aretama | Home - Distribuidora de pollo crudo</title>
                <link rel="canonical" href="https://aretama.com/"/>
            </Helmet>

            <Typography component={'h1'} sx={{display: 'none'}}>Distribuidora de pollo en bogota</Typography>
            <Typography component={'h2'} sx={{display: 'none'}}>Venta de pollo crudo</Typography>
            <Typography component={'h2'} sx={{display: 'none'}}>Pollo entero</Typography>
            <Typography component={'h2'} sx={{display: 'none'}}>Pollo al por mayor</Typography>
            <Typography component={'h2'} sx={{display: 'none'}}>Pollo en presas</Typography>
            <Typography component={'h2'} sx={{display: 'none'}}>Pollo crudo</Typography>

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{maxWidth: "1400px", px: 3}}
            >

                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: sCell ? 0 : 2}}>
                    <TituloImagen/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: sCell ? 10 : 14}}>
                    <Categorias/>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12}
                      sx={{justifyContent: "center", marginTop: sCell ? 10 : 20}}>
                    <Nosotros/>
                </Grid>

                {/*} <Grid item container lg={12} sm={12} xs={12}
                      sx={{justifyContent: "center", marginTop: sCell ? 10 : 14}}>
                    <Certificado/>
                </Grid>*/}

                <Grid item container lg={12} sm={12} xs={12}
                      sx={{justifyContent: "center", marginTop: sCell ? 10 : 16}}>
                    <MantenteInformado/>
                </Grid>


                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: sCell ? 12 : 16}}>
                    <Testimonios/>
                </Grid>

            </Grid>

        </Grid>
    )
}

export default Home;