export const iniciarChatWhatsApp = ({num, nombre = " ", tema = " "}) => {


    const text = `https://api.whatsapp.com/send?phone=57${num}&text=${procesarTexto(nombre)}%20${procesarTexto(tema)}`

    window.open(text)

}

const procesarTexto = (text) => {

    if (text === "") {
        return text
    } else {
        let noEspacios = text.replaceAll(" ", "%20")
        return noEspacios
    }


}