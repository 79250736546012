/**************************************************
 * Nombre:       BotonAsesores
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useEffect, useRef, useState} from 'react';
import {Avatar, Button, Grid, Menu, MenuItem, Typography, useMediaQuery} from "@mui/material";
import {COLORPRIMARIO} from "../../../Constantes";
import {iniciarChatWhatsApp} from "../../../Utilidades/iniciarChatWhatsApp";
import {theme} from "../../../Tema";
import {marcarEvento} from "../../../Servicios/Analiticas/marcarEvento";
import {obtenerDoc} from "../../../Servicios/BD/obtenerDoc";
import {obtenerCol} from "../../../Servicios/BD/obtenerCol";
import {width} from "@mui/system";


const BotonAsesores = ({titulo = "Hablar con un asesor"}) => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const [asesores, setAsesores] = useState([])
    const [open, setOpen] = useState(false)
    const ref = useRef()

    const abrir = () => {
        setOpen(true)
        marcarEvento("Abre chat asesores")
    }

    const cerrar = () => {
        setOpen(false)
    }


    useEffect(() => {
        obtenerCol('asesores').then((dox) => {
            if (dox.res) {
                setAsesores(dox.data)
            } else {
                setAsesores([])
            }
        })
    }, [])
    return (
        <Grid
            container
            direction="row"
            justifyContent={sCell ? "center" : "flex-start"}
            alignItems="flex-start"
        >

            <Menu
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={open} onClose={cerrar} anchorEl={ref.current}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    sx={{width: 290}}
                >

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                        <Typography sx={{fontWeight: 600}}>Asesores Disponibles</Typography>
                    </Grid>

                    <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 1}}>
                        {asesores.map((item) => {
                            return (
                                <MenuItem
                                    onClick={() => {
                                    marcarEvento("inicia_chat_" + item.nombre)
                                    iniciarChatWhatsApp({
                                        num: item.celular,
                                        nombre: item.nombre,
                                        tema: ""
                                    })

                                }}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        sx={{width: 400}}

                                    >

                                        <Grid item lg={3} sm={3} xs={3}>
                                            <Avatar src={item.img}/>
                                        </Grid>

                                        <Grid item lg={9} sm={9} xs={9}>
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="flex-start"
                                                alignItems="flex-start"
                                            >

                                                <Grid item lg={12} sm={12} xs={12}>
                                                    <Typography sx={{fontSize: 14}}>{item.cargo}</Typography>
                                                </Grid>

                                                <Grid item lg={12} sm={12} xs={12}>
                                                    <Typography>{item.nombre}</Typography>
                                                </Grid>

                                            </Grid>

                                        </Grid>

                                    </Grid>
                                </MenuItem>
                            )
                        })}
                    </Grid>

                </Grid>
            </Menu>

            <Button
                ref={ref}
                onClick={(e) => abrir(e)}
                color={"secondary"}
                sx={{color: COLORPRIMARIO}}
            >{titulo}</Button>


        </Grid>
    )
}

export default BotonAsesores;


const letasesores = [

    {
        img: "",
        cargo: "Planta Principal",
        celular: "3163039997",
        nombre: ""

    },
    {
        img: "",
        celular: "3124555566",
        cargo: "Punto de Venta Calle 53",
        nombre: ""
    },
    {
        img: "",
        celular: "3163161710",
        cargo: "Venta Granjas",
        nombre: ""
    },
]