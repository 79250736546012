import {createTheme} from '@mui/material/styles';
import textura from "./Recursos/textura.png";


export const theme = createTheme({
    palette: {
        primary: {
            main: "#9B1C11",
        },
        secondary: {
            main: "#FFA400",
        },
        info: {
            main: "#560283",
        },
        success: {
            main: "#C3C70080"
        },
        text:{
            primary: "#3B3B3B",
        }


    },
    typography: {
        fontFamily: ['Montserrat', 'sans-serif'].join(','),


    },
    components: {
        MuiTextField: {
            defaultProps: {
                fullWidth: true,
            },

        }, MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                    color: "#fff",

                }
            }
        }, MuiButtonBase: {
            variants: [
                {
                    props: { variant: 'nosombra' },
                    style: {
                        transition: 'all .1s ease-in-out',
                        borderRadius: 10,
                        '&: hover': {
                            marginTop : -6,
                            boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",

                        }
                    },
                },
                {
                    props: { variant: 'sombra' },
                    style: {
                        transition: 'all .1s ease-in-out',
                        borderRadius: 10,
                        '&: hover': {
                            marginTop : -6,
                            boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)"
                        }
                    },
                },

            ],

        }, MuiDialog: {
            styleOverrides: {
                paper: {
                    borderRadius: 12,
                    background: "radial-gradient(circle, rgba(255,39,0,1) 0%, rgba(77,22,16,1) 100%)",


                }
            }
        }
        , MuiIconButton: {
            styleOverrides: {
                root: {
                    transition: 'all .2s ease-in-out',
                    borderRadius: 10,
                    '&: hover': {
                        boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)"
                    }
                }
            }
        },
        MuiButton: {
            defaultProps:{
                variant: "contained"
            },
            styleOverrides: {
                root:{
                    fontSize: 16,
                    fontWeight: 600,
                    paddingLeft: 25,
                    paddingRight: 25,
                    borderRadius: 0
                }
            }
        },
        MuiDrawer:{
            styleOverrides:{
                paper:{
                    backgroundColor: "#9B1C11"
                }
            }
        },
        MuiChip:{
            defaultProps:{
              color: "secondary"
            },
            styleOverrides:{
                root:{
                    fontWeight: 600,
                    color: "#1C1C1C"

                }
            }
        }

    }


})
