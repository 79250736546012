/**************************************************
 * Nombre:       Granjas
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid, Typography, useMediaQuery} from "@mui/material";
import img from "../../../Recursos/img_nosotros.png";
import ceparador from "../../../Recursos/ceparador.svg";
import {theme} from "../../../Tema";
import GranjaImgIzquierda from "./GranjaImgIzquierda";
import GranjaImgDerecha from "./GranjaImgDerecha";


const Granjas = () => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))


    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid

                component={"div"}
                data-aos="fade-up"
                data-aos-delay={100}
                data-aos-duration="1500"
                item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: sCell ? 10 : 18}}>
                <img src={ceparador} width={sCell ? "80%" : "40%"}/>
            </Grid>


            <Grid

                component={"div"}
                data-aos="fade-up"
                data-aos-delay={150}
                data-aos-duration="1500"
                item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: sCell ? 8 : 10}}>
                <Typography sx={{color: "#fff", fontSize: 32, fontWeight: 800, textAlign: "center"}}>NUESTRAS
                    GRANJAS</Typography>
            </Grid>

            <Grid item lg={10} sm={12} xs={12} sx={{marginTop: sCell ? 8 : 14}}>
                <GranjaImgIzquierda/>
            </Grid>








        </Grid>
    )
}

export default Granjas;