/**************************************************
 * Nombre:       TrabajaConNosotros
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Button, Grid, Typography, useMediaQuery} from "@mui/material";
import img from "../../../Recursos/img_nosotros.png";
import ceparador from "../../../Recursos/ceparador.svg";
import {theme} from "../../../Tema";
import circulo from "../../../Recursos/circulo.svg";
import {COLORPRIMARIO, correoAretama} from "../../../Constantes";
import {irUrl} from "../../../Utilidades/irUrl";


const TrabajaConNosotros = () => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))


    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >


            <Grid
                component={"div"}
                data-aos="fade-up"
                data-aos-offset={0}
                data-aos-delay={150}
                data-aos-duration="1500"
                item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: sCell ? 8 : 18}}>
                <img src={ceparador} width={sCell ? "80%" : "40%"}/>
            </Grid>


            <Grid
                component={"div"}
                data-aos="fade-up"
                data-aos-offset={0}
                data-aos-delay={200}
                data-aos-duration="1500"
                item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: sCell ? 6 : 10}}>
                <Typography sx={{color: "#fff", fontSize: 32, fontWeight: 800, textAlign: "center"}}>
                    TRABAJA CON NOSOTROS</Typography>
            </Grid>

            <Grid
                component={"div"}
                data-aos="fade-up"
                data-aos-offset={0}
                data-aos-delay={250}
                data-aos-duration="1500"
                item container lg={10} sm={12} xs={12} sx={{justifyContent: "center", marginTop: sCell ? 6 : 6}}>
                <Typography sx={{color: "#fff", fontSize: 18, fontWeight: 500, textAlign: "center"}}>
                    Trabajo en Bogotá SI HAY. Ven a formar parte de nuestro equipo.
                    <br/>
                    Envíanos tus datos y hoja de vida.
                    <br/>
                    Nosotros te contactaremos

                </Typography>
            </Grid>

            <Grid item lg={10} sm={12} xs={12} sx={{marginTop: 10}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems={sCell ? "flex-start" : "center"}
                    spacing={1}
                >

                    {perfiles.map((item, index) => {
                        return (
                            <Grid
                                key={index}
                                component={"div"}
                                data-aos="fade-up"
                                data-aos-offset={0}
                                data-aos-delay={150 * index}
                                data-aos-duration="1500"
                                item lg={6} sm={12} xs={12}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                >


                                    <Grid item container lg={1} sm={1} xs={1}
                                          sx={{justifyContent: "center", marginTop: sCell ? 1 : 1}}>
                                        <img src={circulo} width={12} height={"auto"}/>
                                    </Grid>
                                    <Grid item lg={11} sm={11} xs={11}>
                                        <Typography sx={{color: "#fff", fontSize: sCell ? 16 : 18}}>{item}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    })}

                </Grid>
            </Grid>


            <Grid
                component={"div"}
                data-aos="fade-up"
                data-aos-delay={150}
                data-aos-duration="1500"
                item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 12}}>

                <Button
                    onClick={() => irUrl(`mailto:${correoAretama}?subject=Envio Hoja de vida`)}
                    color={"secondary"} sx={{color: COLORPRIMARIO}}>Enviar Hoja de Vida</Button>
            </Grid>


        </Grid>
    )
}

export default TrabajaConNosotros;

const perfiles = ["Ingenieros de calidad",
    "Ingenieros de alimentos",
    "Veterinarios",
    "Zootecnistas",
    "Despresadores",
    "Auxiliares de planta",
    "Telemercaderistas",
    "Vendedores de punto"]
