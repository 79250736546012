/**************************************************
 * Nombre:       Tarjeta_Categoria
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {ButtonBase, Grid, Typography, useMediaQuery} from "@mui/material";
import {theme} from "../../Tema";


const Tarjeta_Categoria = ({item, setEscogido, index}) => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))

    return (
        <ButtonBase

            onClick={() => setEscogido(item.nombre)}
            sx={{width: "100%"}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{m: 1}}
            >

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                    <img src={item.img} width={"auto"} height={sCell ? 60 : 100}/>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginBottom: 1}}>
                    <Typography sx={{
                        color: "#fff",
                        textAlign: "center",
                        lineClamp: 2,
                        fontSize: sCell ? 12 : 14
                    }}>{item.nombre}</Typography>
                </Grid>


            </Grid>
        </ButtonBase>
    )
}

export default Tarjeta_Categoria;