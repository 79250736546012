import React from 'react';
import logo from './logo.svg';
import './App.css';
import {Grid} from "@mui/material";
import Pagina from "./Pagina/Pagina";
import textura from './Recursos/textura.png'
import {useEscucharAuth} from "./Servicios/Auth/useEscucharAuth";
import {useEscucharUsuarioToCorreo} from "./Servicios/BD/useEscucharUsuarioToCorreo";
import Dashboard from "./Dashboard/Dashboard";

function App() {
    const {email} = useEscucharAuth()
    const {usuario} = useEscucharUsuarioToCorreo({correo: email})

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{
                background: "radial-gradient(circle, rgba(255,39,0,1) 0%, rgba(77,22,16,1) 100%)",
            }}

        >

            {usuario ? <Dashboard/> :
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    sx={{
                        background: "radial-gradient(circle, rgba(255,39,0,1) 0%, rgba(77,22,16,1) 100%)",
                    }}
                >

                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        sx={{
                            backgroundImage: `url(${textura})`
                        }}
                    >
                        <Pagina/>


                    </Grid>


                </Grid>
            }


        </Grid>
    );
}

export default App;
