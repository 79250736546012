/**************************************************
 * Nombre:       Detalle_Asesor
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useEffect, useState} from 'react';
import {Button, Grid} from "@mui/material";
import {useFormulario} from "../../Modulo_Formularios/Hooks/useFormulario";
import Formulario_Asesor from "../Formularios/Formulario_Asesor";
import {LoadingButton} from "@mui/lab";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import Formulario_Blog from "../Formularios/Formulario_Blog";
import {borrarDoc} from "../../Servicios/BD/borrarDoc";


const Detalle_Blog = ({id, cerrar}) => {
    const {Formulario, props, entidad, limpiarEntidad, setEntidad} = useFormulario({
        Form: Formulario_Blog,
        nombre: 'blog'
    })
    const [cargando, setCargando] = useState(false)

    const guardar = () => {

        if (!entidad.titulo || !entidad.contenido || !entidad.img1) {
            alert('Titulo, contenido y por lo menos una imagen son requeridos')
            return;
        }

        if (!entidad.tipo) {
            entidad.tipo = 'una imagen'
        }


        setCargando(true)
        guardarDoc('blogs', entidad).then((dox) => {
            if (dox.res) {
                limpiarEntidad()
                alert('Cambios realizados con exito')
                cerrar()
            } else {
                alert('Algo inesperado paso, por favor intentelo nuevamente')
            }
            setCargando(false)
        })

    }

    const borrar = () => {

        borrarDoc('blogs', entidad.id).then((dox) => {
            if (dox.res) {
                limpiarEntidad()
                alert('Blog Borrado')

            }
        })
    }

    useEffect(() => {

        if (id) {
            setEntidad(id)
        } else {
            setEntidad({})
        }

    }, [id])


    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Grid item lg={12} sm={12} xs={12}>
                <Formulario {...props} />
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 2, mb: 2}}>
                <LoadingButton
                    loading={cargando}
                    onClick={() => guardar()}
                    variant={'contained'} color={'secondary'}>Guardar Cambios</LoadingButton>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 2}}>
                <Button
                    onClick={() => borrar()}
                    variant={'text'} sx={{color: '#00000090'}}>Borrar</Button>
            </Grid>


        </Grid>
    )
}

export default Detalle_Blog;