/**************************************************
 * Nombre:       ImgsAnimacion
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useState} from 'react';
import {Grid} from "@mui/material";


const ImgsAnimacion = ({imgs, full = false}) => {
    const [arriba, setArriba] = useState(false)

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{transition: 'all .2s ease-in-out'}}
            onMouseEnter={() => setArriba(true)}
            onMouseLeave={() => setArriba(!arriba)}
        >

            <Grid container item lg={12} sm={12} xs={12}
                  sx={{
                      zIndex: 10,
                      marginBottom: arriba ? 0 : -20,
                      transform: arriba ? "rotate(0deg)" : "rotate(15deg)",
                      justifyContent: "center",
                      transition: 'all .2s ease-in-out'
                  }}>
                <img src={imgs[0]} width={full ? "84%" : "70%"} height={"auto"}/>
            </Grid>

            <Grid
                onMouseEnter={() => setArriba(true)}
                onMouseLeave={() => setArriba(false)}
                container item lg={12} sm={12} xs={12}
                sx={{
                    zIndex: 5,
                    justifyContent: "center",
                    transition: 'all .2s ease-in-out',
                    marginTop: 5,
                    marginBottom: 4
                }}>
                <img src={imgs[1]} width={full ? "84%" : "70%"} height={"auto"}/>
            </Grid>

        </Grid>
    )
}

export default ImgsAnimacion;