/**************************************************
 * Nombre:       Nosotros
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useEffect} from 'react';
import {Grid, Typography, useMediaQuery} from "@mui/material";
import CeparadorTitulo from "./Componentes/CeparadorTitulo";
import MiniBlogs from "./Componentes/MiniBlogs";
import Granjas from "./Componentes/Granjas";
import NuestroAliado from "./Componentes/NuestroAliado";
import Testimonios from "./Componentes/Testimonios";
import {theme} from "../../Tema";
import {useDialogo} from "../../Modulo_Dialgos/Hooks/useDialog";
import {useParams} from 'react-router-dom'
import {irArriba} from "../../Utilidades/irArriba";
import {Helmet} from "react-helmet";


const Nosotros = () => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const parms = useParams()


    useEffect(() => {
        console.log(parms)
        if (parms && parms.id) {
            window.location.href = "#aliado"
        } else {
            irArriba()
        }
    }, [])
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Pollos Aretama | Nosotros</title>
                <link rel="canonical" href="https://aretama.com/"/>
            </Helmet>

            <Typography component={'h1'} sx={{display: 'none'}}>Empresa distribuidora de pollo</Typography>
            <Typography component={'h2'} sx={{display: 'none'}}>Venta de pollo crudo</Typography>
            <Typography component={'h2'} sx={{display: 'none'}}>Pollo entero</Typography>
            <Typography component={'h2'} sx={{display: 'none'}}>Pollo al por mayor</Typography>
            <Typography component={'h2'} sx={{display: 'none'}}>Pollo en presas</Typography>
            <Typography component={'h2'} sx={{display: 'none'}}>Pollo crudo</Typography>

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: "1400px", px: 3}}
            >


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                    <CeparadorTitulo titulo={"NOSOTROS"}/>
                </Grid>

                <Grid item container lg={10} sm={12} xs={12} sx={{justifyContent: "center", marginTop: sCell ? 8 : 14}}>
                    <MiniBlogs/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: sCell ? 0 : 2}}>
                    <Granjas/>
                </Grid>

                {/*} <Grid item lg={12} sm={12} xs={12} sx={{marginTop: sCell ? 8 : 16}}>
                    <Certificado/>
                </Grid>*/}

                <Grid item lg={10} sm={12} xs={12} sx={{marginTop: sCell ? 8 : 16}}>
                    <NuestroAliado/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 8}}>
                    <Testimonios/>
                </Grid>


            </Grid>

        </Grid>
    )
}

export default Nosotros;