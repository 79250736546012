/**************************************************
 * Nombre:       Tarjeta_Testimonio
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Avatar, Grid, Typography, useMediaQuery} from "@mui/material";
import {COLORACENTO} from "../../Constantes";
import {theme} from "../../Tema";


const Tarjeta_Testimonio = ({item}) => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{border: 2, borderColor: COLORACENTO, p: 2}}

        >


            <Grid item container lg={2} sm={3} xs={4} sx={{justifyContent: "center"}}>
                <Avatar src={item.img} sx={{width: sCell ? 80 : 90, height: sCell ? 80 :  90}}/>
            </Grid>

            <Grid item lg={10} sm={9} xs={8}  sx={{paddingLeft: 2}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"

                >

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "flex-end"}}>
                        <Typography sx={{fontSize: sCell ? 10 : 12, fontWeight: 600, color: "#fff"}}>{item.tipo}</Typography>
                    </Grid>

                    <Grid item lg={12} sm={12} xs={12}>
                        <Typography sx={{fontSize: sCell ? 10 : 12, fontWeight: 600, color: COLORACENTO}}>{item.usuario}</Typography>
                    </Grid>

                    <Grid item lg={12} sm={12} xs={12}>
                        <Typography sx={{fontSize: masSM ? 12 : 10, fontWeight: 600, color: "#fff"}}>{item.testimonio}</Typography>
                    </Grid>

                </Grid>
            </Grid>

        </Grid>
    )
}

export default Tarjeta_Testimonio;