/**************************************************
 * Nombre:       TituloImagen
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useEffect, useRef, useState} from 'react';
import {Grid, Typography, useMediaQuery} from "@mui/material";
import pollo from "../../../Recursos/pollocrudo.png";
import ceparador from "../../../Recursos/ceparador.svg";
import {theme} from "../../../Tema";
import {useBotonChat} from "../../Hooks/useBotonChat";
import BotonAsesores from "./BotonAsesores";
import AOS from "aos";
import "aos/dist/aos.css";
import {obtenerDoc} from "../../../Servicios/BD/obtenerDoc";
import {iniciarChatWhatsApp} from "../../../Utilidades/iniciarChatWhatsApp";

const TituloImagen = () => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const {Boton} = useBotonChat({titulo: "Hablar con un asesor"})
    const ref = useRef()
    const [anuncio, setAnuncio] = useState({})


    useEffect(() => {
        obtenerDoc('anuncio', 'anuncio').then((dox) => {
            if (dox.res) {
                setAnuncio(dox.data)
            } else {
                setAnuncio({})
            }
        })
    }, [])
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{marginTop: sCell ? 0 : 8}}
        >


            <Grid item lg={6} sm={6} xs={12} sx={{marginTop : -8}} >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    sx={{pl: sCell ? 0 : 4}}
                >

                    <Grid
                        component={"div"}
                        data-aos="fade-up"
                        data-aos-delay={100}
                        data-aos-duration="1500"
                        item lg={12} sm={12} xs={12} sx={{marginTop: 12, px: sCell ? 2 : 0}}>
                        <Typography
                            sx={{fontSize: masSM ? 56 : 36, fontWeight: 800, lineHeight: 1.1, color: "#fff"}}>
                            Pollo de calidad, frescura y sabor
                        </Typography>
                    </Grid>

                    <Grid
                        component={"div"}
                        data-aos="fade-up"
                        data-aos-delay={300}
                        data-aos-duration="1500"
                        item lg={12} sm={12} xs={12} sx={{marginTop: 4, px: sCell ? 2 : 0}}>
                        <Typography
                            ref={ref}
                            sx={{fontSize: 18, fontWeight: 500, color: "#fff"}}>
                            Somos una marca líder en venta de pollo en canal, una empresa avícola con más de 90 años de
                            tradición.
                        </Typography>
                    </Grid>

                    <Grid
                        component={"div"}
                        data-aos="fade-right"
                        data-aos-offset={"0"}
                        data-aos-delay={900}
                        data-aos-duration="1500"
                        item container lg={12} sm={12} xs={12}
                        sx={{marginTop: 8, justifyContent: sCell ? "center" : "flex-start"}}>

                        <BotonAsesores/>

                    </Grid>


                </Grid>
            </Grid>

            <Grid

                component={"div"}
                data-aos="fade-left"
                data-aos-delay={400}
                data-aos-duration="1500"
                item container lg={6} sm={6} xs={12}
                onClick={() => iniciarChatWhatsApp({num: anuncio.numero, nombre: '', tema: anuncio.mensaje})}
                sx={{justifyContent: "center", marginTop: sCell ? 6 : 0, cursor: 'pointer'}}>
                <img src={anuncio && anuncio.img ? anuncio.img : pollo} width={"80%"} height={"auto"}/>
            </Grid>


            <Grid
                component={"div"}
                data-aos="fade-up"
                data-aos-duration="1000"
                item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: sCell ? 8 : 10}}>
                <img src={ceparador} width={sCell ? "80%" : "40%"}/>
            </Grid>

        </Grid>
    )
}

export default TituloImagen;