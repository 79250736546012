/**************************************************
 * Nombre:       CatalogoSeccion
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useEffect, useState} from 'react';
import {Button, Grid, Tab, Tabs, useMediaQuery} from "@mui/material";
import Tarjeta_Categoria from "../../Tarjetas/Tarjeta_Categoria";
import pollo from "../../../Recursos/cat_pollo.png";
import procesados from "../../../Recursos/cat_procesados.png";
import congelados from "../../../Recursos/cat_congelados.png";
import crudos from "../../../Recursos/cat_crudos.png";
import Tarjeta_Catalogo from "../../Tarjetas/Tarjeta_Catalogo";
import {theme} from "../../../Tema";
import useRecuperarColleccionListeningProg from "../../../Servicios/BD/useRecuperarColleccionListeningProg";
import {where} from 'firebase/firestore'


const categos = [
    {
        img: procesados,
        nombre: "Procesados"
    },
    {
        img: pollo,
        nombre: "Pollo en Presas"
    },
    {
        img: congelados,
        nombre: "Congelados"
    },
    {
        img: crudos,
        nombre: "Linea Crudos"
    }

]

const Catalogo = ({escogido, setEscogido}) => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))

    const [wheres, setWheres] = useState([])
    const {data, cargarMas} = useRecuperarColleccionListeningProg({
        col: "productos",
        numero: 10,
        wheres: wheres,
        orden: "nombre"
    })


    useEffect(() => {
        setWheres([where("categorias", "array-contains", escogido)])
    }, [escogido])
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: "1400px", px: sCell ? 1 : 3}}
            >

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                    <Tabs value={escogido}
                          indicatorColor={"secondary"}
                          variant={"scrollable"}
                          onChange={(e) => console.log(e)}

                    >
                        {categos.map((item, index) => {
                            return (

                                <Tab
                                    key={index}
                                    label={item.nombre} value={item.nombre}
                                    sx={{maxWidth: 100}}
                                    component={() => {
                                        return (
                                            <Tarjeta_Categoria item={item} setEscogido={setEscogido} index={index}/>
                                        )
                                    }}
                                />


                            )
                        })}


                    </Tabs>
                </Grid>

                <Grid item container lg={11} sm={12} xs={12} sx={{justifyContent: "center", marginTop: sCell ? 6 : 16}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={masSM ? 10 : 4}
                    >

                        {data.map((item, index) => {
                            return (
                                <Grid
                                    key={index}
                                    component={"div"}
                                    data-aos="fade-up"
                                    data-aos-offset={"0"}
                                    data-aos-delay={150 * index}
                                    data-aos-duration="1500"
                                    item lg={4} sm={3} xs={6}>
                                    <Tarjeta_Catalogo item={item}/>
                                </Grid>
                            )
                        })}

                    </Grid>
                </Grid>

                <Grid
                    component={"div"}
                    data-aos="fade-up"
                    data-aos-delay={200}
                    data-aos-duration="1500"
                    item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 8}}>
                    <Button variant={"outlined"} color={"secondary"}>Cargar Mas</Button>
                </Grid>


            </Grid>

        </Grid>
    )
}

export default Catalogo;


