/**************************************************
 * Nombre:       MiniBlogs
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useState, useEffect} from 'react';
import {Grid} from "@mui/material";
import img from '../../../Recursos/fetival.jpg'
import camion from '../../../Recursos/camion.png'
import granja from '../../../Recursos/granja.png'
import Tarjeta_Blog from "../../Tarjetas/Tarjeta_Blog";
import {useDialogo} from "../../../Modulo_Dialgos/Hooks/useDialog";
import Dialogo_Detalle_MiniBlog from "../../Dialogos/Dialogo_Detalle_MiniBlog";
import {obtenerCol} from "../../../Servicios/BD/obtenerCol";


const MiniBlogs = () => {
    const {Dialogo, abrir} = useDialogo({Componente: Dialogo_Detalle_MiniBlog})
    const [blogs, setBlogs] = useState([])


    useEffect(() => {
        obtenerCol('blogs').then((dox) => {
            if (dox.res) {
                setBlogs(dox.data)
            }
        })
    }, [])
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={12}
        >
            <Dialogo/>


            {blogs.map((item, index) => {
                return (
                    <Grid
                        key={index}
                        component={"div"}
                        data-aos="fade-up"
                        data-aos-delay={250 * index}
                        data-aos-duration="1500"

                        item lg={6} sm={6} xs={12} onClick={() => abrir(item)} sx={{cursor: "pointer"}}>
                        <Tarjeta_Blog item={item}/>
                    </Grid>
                )
            })}

        </Grid>
    )
}

export default MiniBlogs;

const blogsArc = [
    {
        titulo: "POLLO FRESCO",
        descripcion: "Gracias a altos estándares de calidad hemos logrado posicionar estratégicamente nuestras granjas para poder llevar un producto fresco y de campo a las mesas de los colombianos. Esto nos ha hecho merecedores de reconocimientos y certificaciones. Todo empezo con Víctor Gómez nacido en Zipaquirá y su espíritu aventurero que lo llevó a darle la vuelta al mundo hasta llegar finalmente a las selvas del Choco donde se dedicó al negocio de la madera. En 1931, decidió desplazarse nuevamente hacia el interior del país y en el Municipio de Tocancipá, a las afueras de Bogotá, en el mes junio se fundo la primera empresa de producción de pollo  En 1970 falleció, luego de una exitosa trayectoria en la industria avícola. Tras lo cual, en 1971 Carlos Eduardo López tomó las riendas de la empresa y hasta la fecha, luego de más de ochenta años de servicio y tradición, su familia sigue liderando su paso hacia el futuro.",
        imgs: [camion, granja]

    },
    {
        titulo: "FESTIVAL DEL POLLO",
        descripcion: "Durante este evento, celebrado entre septiembre y octubre de cada año, dentro de muchas actividades, fue galardonado el mejor pollo de Colombia. Como siempre, Aretama figuró entre las mejores empresas participantes.",
        imgs: [img]

    }
]