/**************************************************
 * Nombre:       CeparadorTitulo
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid, Typography, useMediaQuery} from "@mui/material";
import ceparador from "../../../Recursos/ceparador.svg";
import {COLORACENTO} from "../../../Constantes";
import {theme} from "../../../Tema";


const CeparadorTitulo = ({titulo}) => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))


    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >


            <Grid
                component={"div"}
                data-aos="fade-up"
                data-aos-delay={100}
                data-aos-duration="1500"

                item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: sCell ? 8 : 10}}>
                <img src={ceparador} width={sCell ? "80%" : "40%"}/>
            </Grid>

            <Grid
                component={"div"}
                data-aos="fade-up"
                data-aos-delay={150}
                data-aos-duration="1500"

                item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 2}}>
                <Typography sx={{fontSize: sCell ? 30 : 40, color: COLORACENTO, fontFamily: "Passion One"}}>{titulo}</Typography>
            </Grid>

        </Grid>
    )
}

export default CeparadorTitulo;