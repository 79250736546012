import {Facebook, Instagram, Whatsapp} from "iconsax-react";
import {React} from "react";

export const urlFace = "https://www.facebook.com/profile.php?id=100088820474678"
export const urlInsta = "https://www.instagram.com/pollosaretama/"
export const urlWass = "https://api.whatsapp.com/send?phone=573163161710&text=Hola%20Aretama%2C%20necesito%20mas%20informacion"
const urlMapCalle53 = "https://www.google.com/maps/dir/?api=1&destination=0.8235365,-77.6360752,20&dir_action=navigate";
const urlMapGranjas = "https://www.google.com/maps/dir/?api=1&destination=0.8235365,-77.6360752,20&dir_action=navigate";

export const urlHaccp = "https://www.eurofins-environment.es/es/que-es-el-sistema-haccp/"
export const correoAretama = "nomina@aretama.com"


export const COLORPRIMARIO = "#9B1C11"
export const COLORACENTO = "#FFB700"
export const gradienteChip = "linear-gradient(-90deg, rgba(162,79,0,1) 0%, rgba(255,183,48,1) 100%)"

export const secciones = [
    {
        nombre: "Home",
    },
    {
        nombre: "Catalogo",
    },
    {
        nombre: "Nosotros",
    },
    {
        nombre: "Contactos",
    },
    {
        nombre: "Puntos de Venta",
    },


]

export const redes = [
    {
        img: <Facebook variant={"Bulk"} size={"2rem"} color={"#fff"}/>,
        url: urlFace
    },
    {
        img: <Instagram variant={"Bulk"} size={"2rem"} color={"#fff"}/>,
        url: urlInsta
    },
    {
        img: <Whatsapp variant={"Bulk"} size={"2rem"} color={"#fff"}/>,
        url: urlWass
    },
]

export const tiposClientes = ["Fama", "Carnicería", "Avícola", "Salsamentaria", "Distribuidoras de alimentos", "Restaurante", "Tamalera", "Panadero", "Distribuidor de pollo", "Productor de embutidos", "Colegio", "Casino", "Catering Especializado"]
