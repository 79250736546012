/**************************************************
 * Nombre:       Dashboard
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid} from "@mui/material";
import {
    ArchiveBook, ArchiveBox,
    Award,
    Box,
    HambergerMenu,
    Layer,
    LogoutCurve,
    ShoppingBag,
    UserAdd,
    UserTag,
    UserTick
} from "iconsax-react";
import {salirAunth} from "../Servicios/Auth/funSalir";
import logo from '../Recursos/logo.svg'
import {DashboardBase, Barra} from "../Modulo_Dashboard/Componentes/C_Dashboard";
import Anuncio from "./Secciones/Anuncio";
import Asesores from "./Secciones/Asesores";
import Potenciales from "./Secciones/Potenciales";
import AliadosPotenciales from "./Secciones/AliadosPotenciales";
import Blog from "./Secciones/Blog";
import PQRS from "./Secciones/PQRS";


const Dashboard = () => {

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <DashboardBase componentes={componentes}>

                <DashboardBase.Barra>

                    <Grid item lg={4} sm={4} xs={6}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                        >

                            <Grid item lg={2} sm={2} xs={4}>
                                <Barra.OpenDrawer>
                                    <HambergerMenu color={"#fff"}/>
                                </Barra.OpenDrawer>
                            </Grid>

                            <Grid item lg={10} sm={8} xs={8}>
                                <Barra.Titulo/>
                            </Grid>

                        </Grid>

                    </Grid>


                    <Grid item container lg={8} sm={8} xs={6} sx={{justifyContent: "flex-end"}}>
                        <Barra.Salir
                            click={() => salirAunth()}
                        >
                            <LogoutCurve variant={"Bold"} color={"#fff"}/>
                        </Barra.Salir>
                    </Grid>


                </DashboardBase.Barra>

                <DashboardBase.Drawer credenciales={credenciales} logo={logo}/>

                <DashboardBase.Main/>

            </DashboardBase>


        </Grid>
    )
}

export default Dashboard;

const credenciales = ["Anuncio", "Asesores", 'Potenciales', 'Aliados', 'Blog', 'Pqrs']

const componentes = [
    {
        seccion: <Anuncio/>,
        credencial: "Anuncio",
        icono: <Award variant={"Bold"} color={"#fff"}/>
    },
    {
        seccion: <Asesores/>,
        credencial: "Asesores",
        icono: <UserTick variant={"Bold"} color={"#fff"}/>
    },
    {
        seccion: <Potenciales/>,
        credencial: "Potenciales",
        icono: <UserTag variant={"Bold"} color={"#fff"}/>
    },
    {
        seccion: <AliadosPotenciales/>,
        credencial: "Aliados",
        icono: <UserAdd variant={"Bold"} color={"#fff"}/>
    },
    {
        seccion: <Blog/>,
        credencial: "Blog",
        icono: <ArchiveBook variant={"Bold"} color={"#fff"}/>
    },
    {
        seccion: <PQRS/>,
        credencial: "Pqrs",
        icono: <ArchiveBox variant={"Bold"} color={"#fff"}/>
    },

]