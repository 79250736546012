/**************************************************
 * Nombre:       Pqrs
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useState} from 'react';
import {Button, Grid, Typography, useMediaQuery} from "@mui/material";
import pqrs from '../../../Recursos/tit_pqrs.svg'
import {useFormulario} from "../../../Modulo_Formularios/Hooks/useFormulario";
import {theme} from "../../../Tema";
import img from "../../../Recursos/img_nosotros.png";
import ceparador from "../../../Recursos/ceparador.svg";
import Formulario_Pqrs from "../../Formularios/Formulario_Pqrs";
import {COLORPRIMARIO} from "../../../Constantes";
import {guardarDoc} from "../../../Servicios/BD/guardarDoc";
import {LoadingButton} from "@mui/lab";


const Pqrs = () => {
    const {Formulario, props, entidad, limpiarEntidad} = useFormulario({Form: Formulario_Pqrs, nombre: "aliado"})
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const [cargando, setCargando] = useState(false)

    const registrarInformacion = () => {
        let ent = {...entidad};
        if (!ent.nombre || !ent.correo) {
            alert("Nombre y correo son necesarios para hacerte llegar como vamos a mejorar")
            return
        }
        ent.fecha = new Date().getTime()
        setCargando(true)
        guardarDoc("pqrs", ent).then((dox) => {
            if (dox.res) {
                alert("Gracias por tus comentarios")
                limpiarEntidad()
            } else {
                alert("Ups, sucedio algo mal, intentalo mas tarde")
            }
            setCargando(false)
        })
    }


    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid
                component={"div"}
                data-aos="fade-up"
                data-aos-offset={100}
                data-aos-delay={100}
                data-aos-duration="1500"
                item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: sCell ? 10 : 18}}>
                <img src={ceparador} width={sCell ? "80%" : "40%"}/>
            </Grid>


            <Grid
                component={"div"}
                data-aos="fade-up"
                data-aos-delay={150}
                data-aos-duration="1500"
                item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 14}}>
                <img src={pqrs} width={sCell ? 280 : 280} height={"auto"}/>
            </Grid>

            <Grid
                component={"div"}
                data-aos="fade-up"
                data-aos-delay={200}
                data-aos-duration="1500"
                item lg={8} sm={12} xs={12} sx={{marginTop: 10}}>
                <Typography
                    sx={{color: "#fff", fontSize: 18, fontWeight: 500, textAlign: "center"}}
                >
                    Estamos en un proceso continuo de mejoramiento de nuestros procesos.
                    Aún no somos perfectos para todos, pero podemos serlo para ti.
                </Typography>
            </Grid>


            <Grid
                component={"div"}
                data-aos="fade-up"
                data-aos-delay={250}
                data-aos-duration="1500"
                item lg={8} sm={10} xs={12} sx={{marginTop: 10}}>
                <Formulario {...props} />
            </Grid>

            <Grid
                component={"div"}
                data-aos="fade-up"
                data-aos-delay={150}
                data-aos-offset={0}
                data-aos-duration="1500"
                item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 8}}>
                <LoadingButton
                    variant={"contained"}
                    loading={cargando}
                    onClick={() => registrarInformacion()}
                    color={"secondary"} sx={{color: COLORPRIMARIO}}>Enviar</LoadingButton>
            </Grid>

        </Grid>
    )
}

export default Pqrs;