/**************************************************
 * Nombre:       Tarjeta_Catalogo
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {ButtonBase, Chip, Grid, Typography, useMediaQuery} from "@mui/material";
import {COLORACENTO} from "../../Constantes";
import {theme} from "../../Tema";
import {useNavigate} from "react-router-dom";


const Tarjeta_Catalogo = ({item}) => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const navigate = useNavigate()

    return (
        <ButtonBase
            onClick={() => navigate("/Detalle/" + item.id)}

            sx={{width: "100%", textAlign: "left", borderRadius: 2}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={1}
            >
                <Grid item lg={12} sm={12} xs={12} sx={{marginBottom: -0.3}}>
                    <img src={item.img} width={"100%"} height={"auto"}
                         style={{borderRadius: 12}}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >

                        <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 0}} >
                            <Typography
                                sx={{
                                    color: "#fff",
                                    fontSize: masSM ? 40 : 20,
                                    fontWeight: 700,
                                    fontFamily: "Passion One",
                                    textTransform: "uppercase",
                                    lineHeight: 1

                                }}>{item.nombre}</Typography>
                        </Grid>

                        <Grid item lg={12} sm={12} xs={12} sx={{marginTop: masSM ? 2 : 1}} >
                            <Typography
                                sx={{
                                    color: "#fff",
                                    fontSize: masSM ? 16 : 10,
                                    fontWeight: 600,
                                    display: '-webkit-box',
                                    overflow: 'hidden',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 3,
                                    textAlign: "justify",
                                    textOverflow: "ellipsis",
                                }}>{item.descripcion}</Typography>
                        </Grid>

                        <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 1}}>

                            <Typography
                                sx={{color: "#fff", fontSize: masSM ? 12 : 10, fontWeight: 500}}
                            >{"TIPOS DE PERSENTACIONES: "}<span>{item && item.empaques && item.empaques.length}</span></Typography>

                        </Grid>

                        <Grid item lg={12} sm={12} xs={12}>
                            {item && item.etiquetas && item.etiquetas.map((item, index) => {
                                return (
                                    <Chip
                                        label={item}
                                        size={"small"}
                                        sx={{
                                            marginRight: 2,
                                            px: 1,
                                            mt: 1,
                                            fontSize: 12
                                        }}/>
                                )
                            })}
                        </Grid>

                    </Grid>
                </Grid>

            </Grid>

        </ButtonBase>
    )
}

export default Tarjeta_Catalogo;