/**************************************************
 * Nombre:       Pagina
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useEffect} from 'react';
import {Grid} from "@mui/material";
import Barra from "./Barra/Barra";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Home from "./Secciones/Home";
import Footer from "./Footer";
import Productos from "./Secciones/Productos";
import Detalle from "./Secciones/Detalle";
import Nosotros from "./Secciones/Nosotros";
import Contactos from "./Secciones/Contactos";
import PuntosVenta from "./Secciones/PuntosVenta";
import AOS from "aos";
import CatalogoSeccion from "./Secciones/CatalogoSeccion";
import {iniciarAnaliticas} from "../Servicios/Analiticas/iniciarAnaliticas";

const Pagina = () => {

    useEffect(() => {
        AOS.init({
            offset: 0
        });
        AOS.refresh();

        iniciarAnaliticas()

    }, []);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{overflowX: "hidden"}}
        >

            <Router>
                <Barra/>
                <Routes>
                    <Route exact path={"/"} element={<Home/>}/>
                    <Route exact path={"*"} element={<Home/>}/>
                    <Route exact path={"/Home"} element={<Home/>}/>
                    <Route exact path={"/Productos/:id"} element={<Productos/>}/>
                    <Route exact path={"/Productos"} element={<Productos/>}/>
                    <Route exact path={"/Detalle/:id"} element={<Detalle/>}/>
                    <Route exact path={"/Nosotros"} element={<Nosotros/>}/>
                    <Route exact path={"/Nosotros/:id"} element={<Nosotros/>}/>
                    <Route exact path={"/Contactos"} element={<Contactos/>}/>
                    <Route exact path={"/Puntos de Venta"} element={<PuntosVenta/>}/>
                    <Route exact path={"/Catalogo"} element={<CatalogoSeccion/>}/>
                    <Route exact path={"/Catalogo/:id"} element={<CatalogoSeccion/>}/>


                </Routes>

                <Footer/>
            </Router>


        </Grid>
    )
}

export default Pagina;