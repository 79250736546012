/**************************************************
 * Nombre:       Detalle
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useEffect, useState} from 'react';
import {Chip, Grid, Typography, useMediaQuery} from "@mui/material";
import {COLORACENTO, gradienteChip} from "../../Constantes";
import {theme} from "../../Tema";
import {useNavigate, useParams} from 'react-router-dom'
import {obtenerDoc} from "../../Servicios/BD/obtenerDoc";
import BotonAsesores from "./Componentes/BotonAsesores";
import {irArriba} from "../../Utilidades/irArriba";


const Detalle = () => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const parms = useParams()
    const navigate = useNavigate()
    const [item, setItem] = useState({})


    useEffect(() => {

        irArriba()
        if (parms && parms.id) {
            obtenerDoc("productos", parms.id).then((dox) => {
                if (dox.res) {
                    setItem(dox.data)
                }
            })
        }
    }, [parms])

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >


            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: "1400px", px: 3}}
            >


                <Grid item container lg={10} sm={10} xs={12}
                      sx={{justifyContent: "center", marginTop: sCell ? 10 : 14}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >

                        <Grid
                            component={"div"}
                            data-aos="fade-right"
                            data-aos-delay={100}
                            data-aos-duration="1500"
                            item container lg={5} sm={4} xs={12}
                            sx={{justifyContent: sCell ? "center" : "flex-start"}}>

                            <img src={item.img} width={"90%"} height={"auto"} style={{
                                borderRadius: 14,
                                boxShadow: ""
                            }}/>
                        </Grid>

                        <Grid item lg={7} sm={8} xs={12} sx={{marginTop: sCell ? 2 : 0}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                sx={{px: sCell ? 2 : 0}}
                            >

                                <Grid
                                    component={"div"}
                                    data-aos="fade-up"
                                    data-aos-delay={100}
                                    data-aos-offset={0}
                                    data-aos-duration="1500"
                                    item lg={12} sm={12} xs={12} sx={{marginTop: 2}}>
                                    <Typography
                                        sx={{
                                            color: "#fff",
                                            fontSize: 55,
                                            fontWeight: 700,
                                            fontFamily: "Passion One",
                                            textTransform: "uppercase",
                                            lineHeight: 1.1
                                        }}>{item.nombre}</Typography>
                                </Grid>

                                <Grid
                                    component={"div"}
                                    data-aos="fade-up"
                                    data-aos-delay={150}
                                    data-aos-offset={0}
                                    data-aos-duration="1500"
                                    item lg={12} sm={12} xs={12} sx={{marginTop: 0}}>
                                    <Typography
                                        sx={{
                                            color: "#fff",
                                            fontSize: 18,
                                            fontWeight: 500
                                        }}>{item.descripcion}</Typography>
                                </Grid>

                                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 2}}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        component={"div"}
                                        data-aos="fade-up"
                                        data-aos-delay={200}
                                        data-aos-offset={0}
                                        data-aos-duration="1500"
                                    >

                                        <Grid item lg={3} sm={12} xs={12}>
                                            <Typography
                                                sx={{color: "#fff", fontSize: 14, fontWeight: 600}}
                                            >{"TIPOS DE PRESENTACIONES: "}</Typography>
                                        </Grid>

                                        <Grid item lg={9} sm={12} xs={12} sx={{marginTop: masSM ? -1 : 0}}>


                                            {item && item.empaques && item.empaques.map((item, index) => {
                                                return (
                                                    <Chip
                                                        key={index}
                                                        label={item}
                                                        variant={"outlined"}
                                                        size={"small"}
                                                        sx={{
                                                            marginRight: 2,
                                                            px: 1,
                                                            mt: 1,
                                                            fontSize: 12,
                                                            color: COLORACENTO,
                                                            textTransform: "uppercase"

                                                        }}/>
                                                )
                                            })}


                                        </Grid>

                                    </Grid>


                                </Grid>

                                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 2}}>
                                    {item && item.categorias && item.categorias.map((item, index) => {
                                        return (
                                            <Chip
                                                key={index}
                                                onClick={() => navigate("/Productos/" + item)}
                                                label={item}
                                                size={sCell ? "medium" : "small"}
                                                sx={{
                                                    marginRight: 2,
                                                    px: 1,
                                                    mt: 1,
                                                    fontSize: 12,
                                                    cursor: "pointer",
                                                    color: "#fff",
                                                    textTransform: "uppercase",
                                                    backgroundColor: gradienteChip
                                                }}/>
                                        )
                                    })}
                                </Grid>

                                <Grid container item lg={12} sm={12} xs={12}
                                      sx={{marginTop: sCell ? 6 : 6, justifyContent: masSM ? "flex-end" : "center"}}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-end"
                                        alignItems="flex-start"
                                        component={"div"}
                                        data-aos="fade-up"
                                        data-aos-delay={250}
                                        data-aos-offset={0}
                                        data-aos-duration="1500"
                                    >
                                        <Grid item container lg={5} sm={8} xs={12} sx={{justifyContent: "flex-end"}}>
                                            <BotonAsesores/>
                                        </Grid>


                                    </Grid>

                                </Grid>

                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>


            </Grid>

        </Grid>
    )
}

export default Detalle;

const etiquetas = ["Pollo", "Procesado"]
const empaques = ["1/2 Libra, 12 Und", "1 Libra, 24 Und"]