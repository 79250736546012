/**************************************************
 * Nombre:       Formulario_Anuncio
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid} from "@mui/material";
import {
    FormBase,
} from "../../Modulo_Formularios/ComponentesBase/C_Forms";
import {subirUnaImagenCroper} from "../../Servicios/Storage/subirUnaImagenCroper";
import {Message, Whatsapp} from "iconsax-react";
import {LoadingButton} from "@mui/lab";


const Formulario_Asesor = ({...props}) => {

    return (
        <FormBase {...props} >
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={4}
                sx={{p: 2}}
            >

                <Grid item lg={6} sm={12} xs={12}>
                    <FormBase.ImagenDimencion dato={"img"} carpeta={"perfiles"} funcion={subirUnaImagenCroper}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon label={'Nombre'} Icono={Message} dato={'nombre'}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon label={'Numero WhatsApp'} Icono={Whatsapp} type={'number'} dato={'numero'}/>
                </Grid>




            </Grid>
        </FormBase>
    )
}

export default Formulario_Asesor;