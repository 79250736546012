/**************************************************
 * Nombre:       Menu_Lateral_Barra
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {ButtonBase, Grid, Typography} from "@mui/material";
import {COLORACENTO, COLORPRIMARIO, redes, secciones} from "../../Constantes";
import {irUrl} from "../../Utilidades/irUrl";
import {useNavigate} from "react-router-dom";
import pollo from "../../Recursos/cat_pollo.png";
import procesados from "../../Recursos/cat_procesados.png";
import congelados from "../../Recursos/cat_congelados.png";
import crudos from "../../Recursos/cat_crudos.png";
import escaparate from "../../Recursos/escaparate.png";
import logo from "../../Recursos/logo.svg";



const Categoria = ({item}) => {

    return (
        <ButtonBase variant={"nosombra"} sx={{width: "100%"}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{height: 100}}
            >

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                    <img src={item.img} width={"40%"}/>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                    <Typography sx={{color: "#fff", fontSize:  14, fontWeight: 600}}>{item.nombre}</Typography>
                </Grid>

            </Grid>
        </ButtonBase>
    )
}



const Menu_Lateral_Barra = () => {
    const navigate = useNavigate()

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{width: 250, p: 2}}
        >

            <Grid item lg={12} sm={8} xs={8} sx={{marginTop: 4}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >

                    {redes.map((red, index) => {
                        return (
                            <Grid item container lg={4} sm={4} xs={4} sx={{justifyContent: "center"}}
                                  key={index}>
                                <ButtonBase
                                    variant={"sombra"}
                                    onClick={() => irUrl(red.url)}
                                >
                                    {red.img}
                                </ButtonBase>
                            </Grid>
                        )
                    })}

                </Grid>
            </Grid>

            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 6}}  >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >

                    {secciones.map((seccion, index) => {
                        return (
                            <Grid item container lg={2} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 1}}
                                  key={index}>

                                <ButtonBase
                                    variant={"nosombra"}
                                    onClick={() => navigate("/" + seccion.nombre)}

                                >
                                    <Typography sx={{
                                        fontFamily: "Passion One",
                                        fontSize: 28,
                                        color: COLORACENTO,
                                    }}>  {seccion.nombre}</Typography>

                                </ButtonBase>
                            </Grid>
                        )
                    })}

                </Grid>
            </Grid>


            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 6}} >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >


                    {categos.map((catego) => {
                        return (
                            <Grid item container lg={3} sm={6} xs={6} sx={{justifyContent: "center"}}>
                                <Categoria item={catego}/>
                            </Grid>

                        )
                    })}

                </Grid>
            </Grid>

            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 10}} >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                        <img src={escaparate} width={180} height={"auto"}/>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                        <img src={logo} width={160} height={"auto"}/>
                    </Grid>

                </Grid>
            </Grid>


        </Grid>
    )
}

export default Menu_Lateral_Barra;

const categos = [
    {
        img: pollo,
        nombre: "Linea Pollo"
    },
    {
        img: procesados,
        nombre: "Linea Procesados"
    },
    {
        img: congelados,
        nombre: "Linea Congelados"
    },
    {
        img: crudos,
        nombre: "Linea Crudos"
    }

]