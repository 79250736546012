/**************************************************
 * Nombre:       Formulario_Registro_Usuario
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid} from "@mui/material";
import {FormBase} from "../../Modulo_Formularios/ComponentesBase/C_Forms";
import {Google, Message, Mobile, Tag2, User} from "iconsax-react";
import {tiposClientes} from "../../Constantes";


const Formulario_Registro_Usuario = ({...props}) => {

    return (
        <FormBase {...props} >
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={4}
            >


                <Grid item lg={6} sm={6} xs={12}>
                    <FormBase.InputIcon dato={"nombre"} label={"Nombre"}
                                        Icono={User}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={12}>
                    <FormBase.InputIcon dato={"correo"} label={"Correo"}
                                        Icono={Google}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={12}>
                    <FormBase.InputIcon dato={"celular"} label={"Celular"}
                                        Icono={Mobile}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={12}>
                    <FormBase.InputIconSelect opciones={tiposClientes.sort()} dato={"tipo"}
                                              label={"Con quien te indentificas"}
                                              Icono={Tag2}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon dato={"mensaje"} lineas={3} label={"Mensaje (opcional)"}
                                        Icono={Message}/>
                </Grid>

            </Grid>

        </FormBase>
    )
}

export default Formulario_Registro_Usuario;