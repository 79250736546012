/**************************************************
 * Nombre:       Testimonios
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid} from "@mui/material";
import fotoAmelia from '../../../Recursos/foto_amelia.jpeg'
import Tarjeta_Testimonio from "../../Tarjetas/Tarjeta_Testimonio";
import fotoLuis from '../../../Recursos/foto_luis.jpeg'


const Testimonios = () => {

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: "1400px", px: 3}}
            >

                <Grid item lg={10} sm={12} xs={12}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={4}
                    >


                        {testis.map((item, index) => {
                            return (
                                <Grid
                                    key={index}
                                    component={"div"}
                                    data-aos="fade-up"
                                    data-aos-delay={150 * index}
                                    data-aos-duration="1500"

                                    item lg={6} sm={6} xs={12}>
                                    <Tarjeta_Testimonio item={item}/>
                                </Grid>
                            )
                        })}

                    </Grid>
                </Grid>


            </Grid>

        </Grid>
    )
}

export default Testimonios;

const testis = [
    {
        img: fotoAmelia,
        usuario: "@amelialopez",
        tipo: "Cliente",
        testimonio: "El pollo es fresco, se nota mucho en el color, soy muy exigente con los alimentos y Aretama cumple con mis expectativas."
    },
    {
        img: fotoLuis,
        usuario: "@liuscardona258",
        tipo: "Empleado",
        testimonio: "Llevo trabajando 3 años con ellos, su calidad humana y sus procesos definidos me entusiasma."
    },
]