/**************************************************
 * Nombre:       Tarjeta_Asesores
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Avatar, ButtonBase, Grid} from "@mui/material";
import {CardBase, Imagen} from "../../Modulo_Tarjetas/Componentes/C_Cards";
import {ArchiveBook, User, Whatsapp} from "iconsax-react";


const Tarjeta_Blog = ({valores, click}) => {

    return (
        <ButtonBase
            onClick={() => click(valores)}
            sx={{width: '100%', textAlign: 'left', borderRadius: 2, '&:hover': {boxShadow: 8}}}>
            <CardBase valores={valores}>

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                >

                    <Grid item lg={1} sm={2} xs={6}>
                        <CardBase.Imagen tamano={'80%'} dato={'img1'}/>
                    </Grid>

                    <Grid item lg={1} sm={2} xs={6}>
                        {valores.img2 && <CardBase.Imagen tamano={'80%'} dato={'img2'}/>}

                    </Grid>

                    <Grid item lg={2} sm={3} xs={12}>
                        <CardBase.IconTexto Icono={ArchiveBook} dato1={'titulo'}/>
                    </Grid>

                    <Grid item lg={8} sm={5} xs={12}>
                        <CardBase.IconTextoParrafo dato1={'contenido'} titulo={'Contenido: '} lineas={2}/>
                    </Grid>


                </Grid>

            </CardBase>
        </ButtonBase>
    )
}

export default Tarjeta_Blog;