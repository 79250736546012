/**************************************************
 * Nombre:       Superior
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useState} from 'react';
import {ButtonBase, Drawer, Grid, Typography, useMediaQuery} from "@mui/material";
import {COLORACENTO, redes, urlFace, urlInsta, urlWass} from "../../../Constantes";
import {Award, Facebook, HambergerMenu, Instagram, Whatsapp} from "iconsax-react";
import {irUrl} from "../../../Utilidades/irUrl";
import {theme} from "../../../Tema";
import Menu_Lateral_Barra from "../../Menus/Menu_Lateral_Barra";
import {useNavigate} from "react-router-dom";


const Superior = () => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const [open, setOpen] = useState(false)
    const navigate = useNavigate()

    const abrir = () => {
        setOpen(true)
    }

    const cerrar = () => {
        setOpen(false)
    }

    return (
        <>
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
            >


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "flex-end"}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                    >


                        <Grid item container lg={6} sm={8} xs={10}
                              sx={{justifyContent: "flex-end"}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                            >


                                <Grid item lg={5} sm={7} xs={12}>
                                    <ButtonBase
                                        onClick={() => navigate("/Nosotros/aliado")}
                                        sx={{textAlign: "left", px: 1, width: "100%"}}>
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                            sx={{
                                                borderRight: masSM ? 2 : 0,
                                                borderColor: COLORACENTO,
                                                cursor: "pointer"
                                            }}
                                        >

                                            <Grid item container lg={2} sm={4} xs={5}
                                                  sx={{
                                                      justifyContent: masSM ? "flex-start" : "flex-end",
                                                      pr: masSM ? 0 : 1
                                                  }}>
                                                <Award variant={"Bulk"} size={sCell ? "2rem" : "2rem"} color={"#fff"}/>
                                            </Grid>

                                            <Grid item container lg={10} sm={8} xs={5}
                                                  sx={{justifyContent: sCell ? "flex-end" : "flex-start"}}>

                                                <Typography
                                                    sx={{
                                                        fontWeight: 600,
                                                        fontSize: sCell ? 12 : 14,
                                                        color: "#fff",

                                                    }}>SE
                                                    NUESTRO
                                                    ALIADO</Typography>

                                            </Grid>

                                        </Grid>
                                    </ButtonBase>

                                </Grid>


                                {masSM && redes.map((red, index) => {
                                    return (
                                        <Grid item container lg={1} sm={1} xs={12} sx={{justifyContent: "flex-end"}}
                                              key={index}>
                                            <ButtonBase
                                                variant={"sombra"}
                                                onClick={() => irUrl(red.url)}
                                            >
                                                {red.img}
                                            </ButtonBase>
                                        </Grid>
                                    )
                                })}


                            </Grid>
                        </Grid>

                        {!masSM &&
                        <Grid item lg={4} sm={1} xs={2}>
                            <ButtonBase
                                onClick={() => abrir()}
                                sx={{backgroundColor: COLORACENTO, p: 1, borderRadius: 2}}>
                                <HambergerMenu color={"#fff"}/>
                            </ButtonBase>
                        </Grid>}

                    </Grid>
                </Grid>


            </Grid>

            <Drawer
                open={open}
                onClose={cerrar}
                anchor={"right"}
                variant={"temporary"}
            >

                <Menu_Lateral_Barra/>


            </Drawer>


        </>
    )
}

export default Superior;


