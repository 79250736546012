/**************************************************
 * Nombre:       Tarjeta_Blog
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid, Typography} from "@mui/material";
import ImgsAnimacion from "../Secciones/Componentes/ImgsAnimacion";


const ImagenSimple = ({imagen}) => {
    return (
        <img src={imagen} width={"100%"} height={"auto"} style={{border: '50px', borderColor: '#fff'}}/>
    )
}

const Tarjeta_Blog = ({item}) => {

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Grid item lg={12} sm={12} xs={12}>
                {item && item.img1 && item.img2 ? <ImgsAnimacion imgs={[item.img1, item.img2]} full={true}/> :
                    <ImagenSimple imagen={item.img1}/>}
            </Grid>

            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 4}}>
                <Typography sx={{color: "#fff", fontWeight: 800, fontSize: 32}}>{item.titulo}</Typography>
            </Grid>


            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 1}}>
                <Typography sx={{
                    color: "#fff",
                    fontSize: 16,
                    fontWeight: 500,
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 7,
                    textAlign: "justify",
                    textOverflow: "ellipsis",
                }}>{item.contenido}</Typography>
            </Grid>


        </Grid>
    )
}

export default Tarjeta_Blog;