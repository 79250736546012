/**************************************************
 * Nombre:       Barra
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useEffect} from 'react';
import {Grid, useMediaQuery} from "@mui/material";
import logo from '../../Recursos/logodesde.svg'
import Superior from "./Componentes/Superior";
import Inferior from "./Componentes/Inferior";
import {theme} from "../../Tema";
import {useNavigate} from 'react-router-dom'
import AOS from "aos";


const Barra = () => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const navigate = useNavigate()



    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                sx={{maxWidth: "1400px", px: 3, marginTop: sCell ? -1 : -2}}
            >


                <Grid item lg={1} sm={2} xs={4}  sx={{marginTop: masSM ? -4 : -1}} >
                    <img src={logo} width={sCell ? 140 : 200} height={"auto"}
                         onClick={() => navigate("/Home")}
                         style={{cursor: "pointer"}}
                    />
                </Grid>

                <Grid item container lg={11} sm={10} xs={8} sx={{justifyContent: "flex-end"}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="flex-start"
                    >

                        <Grid item container lg={12} sm={12} xs={12}
                              sx={{justifyContent: "flex-end", marginTop: sCell ? 2 : 4}}>
                            <Superior/>
                        </Grid>

                        {masSM &&
                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "flex-end", marginTop: 2}}>
                            <Inferior/>
                        </Grid>}

                    </Grid>
                </Grid>

            </Grid>

        </Grid>
    )
}

export default Barra;

