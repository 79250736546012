/**************************************************
 * Nombre:       Nosotros
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {ButtonBase, Grid, Typography, useMediaQuery} from "@mui/material";
import camion from '../../../Recursos/camion.png'
import granja from '../../../Recursos/granja.png'
import {COLORACENTO} from "../../../Constantes";
import ceparador from "../../../Recursos/ceparador.svg";
import {theme} from "../../../Tema";
import ImgsAnimacion from "./ImgsAnimacion";
import {useNavigate} from 'react-router-dom'


const Nosotros = () => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const navigate = useNavigate()

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >


            <Grid
                container
                direction="row-reverse"
                justifyContent="flex-start"
                alignItems="center"
                sx={{maxWidth: "1400px", px: sCell ? 1 : 3}}
            >


                <Grid
                    component={"div"}
                    data-aos="fade-left"
                    data-aos-delay={250}
                    data-aos-duration="1000"
                    item container lg={6} sm={6} xs={12} sx={{justifyContent: "center"}}>

                    <ImgsAnimacion imgs={[camion, granja]}/>

                </Grid>


                <Grid item lg={6} sm={6} xs={12} sx={{marginTop: sCell ? 6 : 0}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >

                        <Grid
                            component={"div"}
                            data-aos="fade-up"
                            data-aos-delay={150}
                            data-aos-duration="1000"
                            item container lg={12} sm={12} xs={12} justifyContent={sCell ? "center" : "flex-start"}>
                            <Typography
                                sx={{color: "#fff", fontSize: masSM ? 40 : 32, fontWeight: 800}}>NOSOTROS</Typography>
                        </Grid>


                        <Grid
                            component={"div"}
                            data-aos="fade-up"
                            data-aos-delay={200}
                            data-aos-duration="1000"
                            item lg={12} sm={12} xs={12} sx={{marginTop: 2}}>
                            <Typography sx={{
                                color: "#fff",
                                fontSize: masSM ? 20 : 18,
                                fontWeight: 500,
                                textAlign: sCell ? "center" : "flex-start"
                            }}>
                                Nacimos en 1931 con el objetivo de alimentar con calidad, frescura y a precio
                                competitivo a las familias colombianas. Contamos con una cadena productiva que nos
                                permite garantizar en todo momento la calidad de nuestro producto...
                            </Typography>
                        </Grid>

                        <Grid
                            component={"div"}
                            data-aos="fade-up"
                            data-aos-delay={250}
                            data-aos-duration="1000"
                            item container lg={12} sm={12} xs={12}
                              sx={{marginTop: 2, justifyContent: sCell ? "center" : "flex-start"}}>
                            <ButtonBase
                                onClick={() => navigate("/Nosotros")}
                            >
                                <Typography sx={{color: COLORACENTO, fontWeight: 800, fontSize: masSM ? 22 : 18}}>Conoce
                                    mas</Typography>

                            </ButtonBase>
                        </Grid>

                    </Grid>
                </Grid>


            </Grid>

            <Grid
                component={"div"}
                data-aos="fade-up"
                data-aos-delay={100}
                data-aos-duration="1000"
                container
                direction="row-reverse"
                justifyContent="flex-start"
                alignItems="center"
                sx={{maxWidth: "1400px", px: 3}}
            >

                <Grid item container lg={12} sm={12} xs={12}
                      sx={{justifyContent: "center", marginTop: sCell ? 10 : 18}}>
                    <img src={ceparador} width={sCell ? "80%" : "40%"}/>
                </Grid>

            </Grid>


        </Grid>
    )
}

export default Nosotros;