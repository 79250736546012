/**************************************************
 * Nombre:       PuntosVenta
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useEffect} from 'react';
import {Grid, Typography, useMediaQuery} from "@mui/material";
import CeparadorTitulo from "./Componentes/CeparadorTitulo";
import TarjetasContacto from "./Componentes/TarjetasContacto";
import {theme} from "../../Tema";
import {Zel} from "iconsax-react";
import ZonasCobertura from "./Componentes/ZonasCobertura";
import {irArriba} from "../../Utilidades/irArriba";
import {Helmet} from "react-helmet";


const PuntosVenta = () => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))

    useEffect(() => {
        irArriba()
    }, [])
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >


            <Helmet>
                <meta charSet="utf-8"/>
                <title>Pollos Aretama | Puntos de Venta</title>
                <link rel="canonical" href="https://aretama.com/"/>
            </Helmet>
            <Typography component={'h1'} sx={{display: 'none'}}>Donde comprar pollo crudo</Typography>
            <Typography component={'h2'} sx={{display: 'none'}}>Venta de pollo crudo</Typography>
            <Typography component={'h2'} sx={{display: 'none'}}>Pollo entero</Typography>
            <Typography component={'h2'} sx={{display: 'none'}}>Pollo al por mayor</Typography>
            <Typography component={'h2'} sx={{display: 'none'}}>Pollo en presas</Typography>
            <Typography component={'h2'} sx={{display: 'none'}}>Pollo crudo</Typography>

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: "1400px", px: 3}}
            >


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                    <CeparadorTitulo titulo={"PUNTOS DE VENTA"}/>
                </Grid>

                <Grid item lg={10} sm={12} xs={12} sx={{marginTop: sCell ? 6 : 10}}>
                    <TarjetasContacto contactos={contas}/>
                </Grid>

                <Grid item lg={10} sm={12} xs={12} sx={{marginTop: sCell ? 6 : 4}}>
                    <ZonasCobertura/>
                </Grid>

            </Grid>


        </Grid>
    )
}

export default PuntosVenta;

const contas = [
    {
        nombre: "Punto de Venta Granjas",
        descripcion: "Ubicado en la Carrera 69 # 16-70",
        numero: "3163161710"
    },
    {
        nombre: "Punto de Venta 53",
        descripcion: "Ubicado en la Avenida Caracas # 52-91",
        numero: "3124555566"
    },


]