/**************************************************
 * Nombre:       Tarjeta_Zona
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Button, ButtonBase, Grid, Typography, useMediaQuery} from "@mui/material";
import img from "../../Recursos/img_nosotros.png";
import {COLORACENTO, COLORPRIMARIO} from "../../Constantes";
import {theme} from "../../Tema";
import circulo from "../../Recursos/circulo.svg";
import {iniciarChatWhatsApp} from "../../Utilidades/iniciarChatWhatsApp";
import {Whatsapp} from "iconsax-react";


const Tarjeta_Zona = ({item, isq = false, index}) => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const sTab = useMediaQuery(theme.breakpoints.only("sm"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))

    return (
        <Grid
            key={index}
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{marginTop: sCell ? 6 : 10}}
        >


            {(isq || sCell) && !sTab &&
            <Grid
                component={"div"}
                data-aos="fade-up"
                data-aos-delay={150}
                data-aos-offset={0}
                data-aos-duration="1500"
                item lg={6} sm={5} xs={12}>
                <img src={item.img} width={sCell ? "100%" : "80%"} height={"auto"} style={{borderRadius: 10}}/>
            </Grid>
            }


            <Grid item lg={6} sm={7} xs={12}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >

                    <Grid item container lg={12} sm={12} xs={12}
                          sx={{justifyContent: "flex-start", marginTop: sCell ? 2 : 0}}>
                        <Typography
                            sx={{color: "#fff", fontSize: 28, fontWeight: 800, textAlign: "left"}}>
                            {item.nombre}</Typography>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12}
                          sx={{justifyContent: "flex-start", marginTop: 2}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >

                            {item && item.localidades && item.localidades.map((it, index) => {
                                return (
                                    <Grid
                                        component={"div"}
                                        data-aos="fade-left"
                                        data-aos-delay={50 * index}
                                        data-aos-offset={0}
                                        data-aos-duration="500"
                                        item lg={6} sm={6} xs={6}>
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                        >

                                            <Grid item container lg={1} sm={1} xs={1}
                                                  sx={{justifyContent: "center", marginTop: sCell ? 1 : 0}}>
                                                <img src={circulo} width={10} height={"auto"}/>
                                            </Grid>
                                            <Grid item lg={11} sm={11} xs={11}>
                                                <Typography
                                                    sx={{color: "#fff", fontSize: sCell ? 16 : 18}}>{it}</Typography>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                )
                            })}

                        </Grid>
                    </Grid>


                </Grid>


                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 4}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={1}
                    >


                        {item && item.numeros.map((numero, index) => {
                            console.log(numero)
                            return (
                                <Grid
                                    key={index}
                                    component={"div"}
                                    data-aos="fade-left"
                                    data-aos-delay={50 * index}
                                    data-aos-offset={0}
                                    data-aos-duration="500"
                                    item lg={12} sm={12} xs={12}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                    >

                                        <Grid item lg={4} sm={5} xs={6}>
                                            <Button
                                                onClick={() => iniciarChatWhatsApp({
                                                    num: numero,
                                                    nombre: " ",
                                                    tema: "Sobre las zonas de domicilio"
                                                })}
                                                startIcon={<Whatsapp size={"1rem"} variant={"Bold"}/>}
                                                color={"secondary"}
                                                size={"small"}
                                                sx={{borderRadius: 5, fontSize: 12, fontWeight: 600}}
                                            > WhatsApp</Button>
                                        </Grid>

                                        <Grid item lg={8} sm={7} xs={6}>
                                            <Typography
                                                sx={{color: "#fff", fontWeight: 600}}>
                                                {numero}
                                            </Typography>
                                        </Grid>

                                    </Grid>
                                </Grid>

                            )
                        })}
                    </Grid>
                </Grid>

            </Grid>


            {!isq && !sCell && masSM &&
            <Grid container item lg={6} sm={5} xs={12} justifyContent={"flex-end"}>
                <img src={item.img} width={sCell ? "100%" : "80%"} height={"auto"} style={{borderRadius: 10}}/>
            </Grid>
            }

            {sTab &&
            <Grid container item lg={6} sm={5} xs={12} justifyContent={"flex-end"}>
                <img src={item.img} width={sCell ? "100%" : "80%"} height={"auto"} style={{borderRadius: 10}}/>
            </Grid>
            }


        </Grid>
    )
}

export default Tarjeta_Zona;