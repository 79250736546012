/**************************************************
 * Nombre:       ZonasCobertura
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid, Typography, useMediaQuery} from "@mui/material";
import img from "../../../Recursos/img_nosotros.png";
import ceparador from "../../../Recursos/ceparador.svg";
import {theme} from "../../../Tema";
import Tarjeta_Zona from "../../Tarjetas/Tarjeta_Zona";
import NuestroAliado from "./NuestroAliado";


const ZonasCobertura = () => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))


    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid
                component={"div"}
                data-aos="fade-up"
                data-aos-delay={100}
                data-aos-offset={0}
                data-aos-duration="1500"
                item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: sCell ? 8 : 18}}>
                <img src={ceparador} width={sCell ? "80%" : "40%"}/>
            </Grid>


            <Grid
                component={"div"}
                data-aos="fade-up"
                data-aos-delay={150}
                data-aos-offset={0}
                data-aos-duration="1500"
                item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: sCell ? 6 : 12}}>
                <Typography sx={{color: "#fff", fontSize: sCell ? 28 : 32, fontWeight: 800, textAlign: "center"}}>
                    DOMICILIOS Y ZONAS DE COBERTURA</Typography>
            </Grid>


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                {zonas.map((item, index) => {
                    return (
                        <Tarjeta_Zona item={item} isq={index % 2 === 0} index={index}/>
                    )
                })}
            </Grid>

            <Grid item lg={10} sm={12} xs={12} sx={{marginTop: sCell ? 8 : 16}}>
                <NuestroAliado/>
            </Grid>


        </Grid>
    )
}

export default ZonasCobertura;

const zonas = [
    {
        nombre: "Granjas",
        localidades: [
            "Monte video",
            "La pradera",
            "Salitre",
            "Bosque popular",
            "La felicidad",
            "Nuevo techo",
            "Hayuelos",
            "Centro Nariño",
            "Modelia",
            "Quinta paredes",
            "Marsella",
            "Villa alsacia",
            "Centro industrial",
            "Ricaurte",
            "Normandia",
            "Castilla",
            "Fontibón",
            "Zona industrial",
            "Paloquemao",
            "Usatama"
        ],
        numeros: ["3163161710"],
        img: "https://firebasestorage.googleapis.com/v0/b/paginaweb-78b1e.appspot.com/o/Grupo%204900.png?alt=media&token=7c0987b4-8161-4874-9bcd-48637a346b97"
    },
    {
        nombre: "Calle 53",
        localidades: [
            "Galerias",
            "Alcázares",
            "Chapinero",
            "La perseverancia",
            "Tuesaquillo",
            "La esmeralda",
            "7 de agosto",
            "Pablo VI",
            "Santa fe",
            "San Luis",
            "Palermo",
            "La soledad",
            "N de Federman",
            "Rosales",
            "La macarena",
            "Chico",
            "Porciúncula",
            "Zona T",
            "Marly",
            "Zona G"
        ],
        numeros: ["3124555566"],
        img: "https://firebasestorage.googleapis.com/v0/b/paginaweb-78b1e.appspot.com/o/Grupo%204899.png?alt=media&token=39f2fcfc-cfad-4914-a7d3-d0c6196195e8"
    }
]