/**************************************************
 * Nombre:       Potenciales
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useState} from 'react';
import {Button, Grid} from "@mui/material";
import {Ho_ManejoTargetas} from "../../Modulo_Tarjetas/Hocs/Ho_ManejoTargetas";
import Tarjeta_Potenciales from "../Tarjetas/Tarjeta_Potenciales";
import useRecuperarColleccionListeningProg from "../../Servicios/BD/useRecuperarColleccionListeningProg";
import {exportarJsonToExcel} from "../Modulo_Excel/Exportar/ExportarJsonToExcel";
import {obtenerCol} from "../../Servicios/BD/obtenerCol";
import {LoadingButton} from "@mui/lab";
import {borrarDoc} from "../../Servicios/BD/borrarDoc";
import Tarjeta_Pqrs from "../Tarjetas/Tarjeta_Pqrs";


const PQRS = () => {
    const [wheres, setWheres] = useState([])
    const {data} = useRecuperarColleccionListeningProg({col: 'pqrs', wheres: wheres, orden: 'fecha'})
    const [cargando, setCargando] = useState(false)


    const borrar = (e) => {
        borrarDoc('pqrs', e.id).then((dox) => {
            if (dox.res) {
                alert("Registro eliminado")
            }
        })
    }

    const exportar = () => {
        setCargando(true)

        obtenerCol('pqrs').then((dox) => {
            if (dox.res) {
                exportarJsonToExcel({data: dox.data, nombre: 'PQRS'})
            } else {
                alert("Algo inesperado paso, intentalo nuevamente")
            }
            setCargando(false)
        })

    }
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{maxWidth: "1400px", py: 2}}
            >

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "flex-end", marginTop: 2}}>
                    <LoadingButton
                        loading={cargando}
                        onClick={() => exportar()}
                        variant={'contained'} color={'secondary'}>Descargar Datos </LoadingButton>
                </Grid>


                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 4}}>
                    <Ho_ManejoTargetas Card={Tarjeta_Pqrs} data={data} click={borrar} />
                </Grid>

            </Grid>


        </Grid>
    )
}

export default PQRS;