/**************************************************
 * Nombre:       GranjaImgIzquierda
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid, Typography, useMediaQuery} from "@mui/material";
import {COLORACENTO} from "../../../Constantes";
import imagen from '../../../Recursos/doblesblanco.png'
import {theme} from "../../../Tema";


const GranjaImgIzquierda = () => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))


    return (
        <Grid
            container
            direction="row-reverse"
            justifyContent="flex-start"
            alignItems="center"
        >


            <Grid
                component={"div"}
                data-aos="fade-left"
                data-aos-delay={200}
                data-aos-duration="1500"
                item container lg={7} sm={7} xs={12} sx={{justifyContent: "center"}}>
                <img src={imagen} width={"90%"} height={"auto"}/>
            </Grid>

            <Grid item lg={5} sm={5} xs={12} sx={{marginTop: sCell ? 4 : 0}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >

                    <Grid
                        component={"div"}
                        data-aos="fade-right"
                        data-aos-delay={200}
                        data-aos-duration="1500"
                        item lg={12} sm={12} xs={12}>
                        <Typography sx={{color: "#fff", fontWeight: 800, fontSize: 32}}>Granjas Productoras</Typography>
                    </Grid>

                    <Grid
                        component={"div"}
                        data-aos="fade-right"
                        data-aos-delay={250}
                        data-aos-duration="1500"
                        item lg={12} sm={12} xs={12} sx={{marginTop: 2}}>
                        <Typography sx={{color: "#fff", fontWeight: 500, fontSize: 20}}>
                            En el departamento de Cundinamarca, muy cerca al clúster productivo, se encuentran nuestras
                            granjas productoras. Esta ventaja en los trayectos nos permite garantizar la calidad de
                            nuestros productos.
                        </Typography>
                    </Grid>

                    <Grid
                        component={"div"}
                        data-aos="fade-right"
                        data-aos-delay={250}
                        data-aos-duration="1500"
                        item lg={12} sm={12} xs={12} sx={{marginTop: 6}}>
                        <Typography sx={{color: "#fff", fontWeight: 500, fontSize: 18}}>Departamento de
                            Cundinamarca</Typography>
                    </Grid>

                    <Grid
                        component={"div"}
                        data-aos="fade-right"
                        data-aos-delay={250}
                        data-aos-duration="1500"
                        item lg={12} sm={12} xs={12} sx={{marginTop: -1}}>
                        <Typography sx={{color: COLORACENTO, fontWeight: 600, fontSize: 14}}>15 Min de
                            Bogota</Typography>
                    </Grid>

                </Grid>
            </Grid>

        </Grid>
    )
}

export default GranjaImgIzquierda;