/**************************************************
 * Nombre:       Tarjeta_Contacto
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Button, Grid, Typography, useMediaQuery} from "@mui/material";
import {theme} from "../../Tema";
import {Whatsapp} from "iconsax-react";
import {irUrl} from "../../Utilidades/irUrl";
import {iniciarChatWhatsApp} from "../../Utilidades/iniciarChatWhatsApp";
import {marcarEvento} from "../../Servicios/Analiticas/marcarEvento";


const Tarjeta_Contacto = ({item}) => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 2}}>
                <Typography sx={{color: "#fff", fontWeight: 700, fontSize: masSM ? 32 : 28}}>{item.nombre}</Typography>
            </Grid>


            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 1}}>
                <Typography sx={{
                    color: "#fff",
                    fontSize: 16,
                    fontWeight: 500,
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 3,
                    textAlign: "justify",
                    textOverflow: "ellipsis",
                }}>{item.descripcion}</Typography>
            </Grid>

            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 2}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >

                    <Grid item lg={4} sm={6} xs={6}>
                        <Button
                            onClick={() =>
                            {
                                iniciarChatWhatsApp(item.numero)
                                marcarEvento("inicia_chat_" + item.nombre)
                            }}
                            startIcon={<Whatsapp size={"1rem"} variant={"Bold"}/>} color={"secondary"}
                            size={"small"}
                            sx={{borderRadius: 5, fontSize: 12, fontWeight: 600}}
                        > WhatsApp</Button>
                    </Grid>

                    <Grid item lg={7} sm={6} xs={6}>
                        <Typography sx={{color: "#fff", fontSize: 14, fontWeight: 600}}>{item.numero}</Typography>
                    </Grid>

                </Grid>
            </Grid>


        </Grid>
    )
}

export default Tarjeta_Contacto;