/**************************************************
 * Nombre:       Anuncio
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useState, useEffect} from 'react';
import {Button, Grid} from "@mui/material";
import {useFormulario} from "../../Modulo_Formularios/Hooks/useFormulario";
import Formulario_Anuncio from "../Formularios/Formulario_Anuncio";
import {LoadingButton} from "@mui/lab";
import {obtenerDoc} from "../../Servicios/BD/obtenerDoc";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import {borrarDoc} from "../../Servicios/BD/borrarDoc";


const Anuncio = () => {
    const {Formulario, props, entidad, setEntidad, limpiarEntidad} = useFormulario({
        Form: Formulario_Anuncio,
        nombre: "anuncio"
    })
    const [cargando, setCargando] = useState(false)

    const guardar = () => {

        if (!entidad.numero || !entidad.img) {
            alert('Numero e Imagen son requeridos')
        }

        setCargando(true)
        let obj = entidad
        obj.id = 'anuncio'

        guardarDoc('anuncio', obj).then((dox) => {
            if (dox.res) {
                alert('Cambios guardados con exito')
            } else {
                alert('Algo inesperado paso, por favor intentalo mas tarde')
            }
            setCargando(false)
        })

    }

    const borrar = () => {
        borrarDoc('anuncio', 'anuncio').then((dox) => {
            if (dox.res) {
                limpiarEntidad()
                alert('Anuncio Borrado, quedara el predenerminado')

            }
        })
    }


    useEffect(() => {
        obtenerDoc('anuncio', 'anuncio').then((dox) => {
            if (dox.res && dox.data) {
                setEntidad(dox.data)
            } else {
                setEntidad({})
            }

        })

    }, [])
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: "1400px", py: 2}}
            >

                <Grid item lg={4} sm={6} xs={8} sx={{marginTop: 4}}>
                    <Formulario {...props} />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 5}}>
                    <LoadingButton
                        onClick={() => guardar()}
                        loading={cargando}
                        variant={'contained'}
                    >
                        guardar cambios
                    </LoadingButton>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 4}}>
                    <Button
                        onClick={() => borrar()}
                        variant={'text'} sx={{color: '#00000090'}}>Borrar</Button>
                </Grid>


            </Grid>


        </Grid>
    )
}

export default Anuncio;