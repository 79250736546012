/**************************************************
 * Nombre:       MantenteInformado
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useState} from 'react';
import {Grid, Typography, useMediaQuery} from "@mui/material";
import titulo from '../../../Recursos/tit_mantente_informado.svg'
import {useFormulario} from "../../../Modulo_Formularios/Hooks/useFormulario";
import Formulario_Registro_Usuario from "../../Formularios/Formulario_Registro_Usuario";
import {COLORPRIMARIO} from "../../../Constantes";
import {theme} from "../../../Tema";
import {guardarDoc} from "../../../Servicios/BD/guardarDoc";
import {LoadingButton} from "@mui/lab";


const MantenteInformado = () => {
    const {Formulario, props, entidad, limpiarEntidad} = useFormulario({
        Form: Formulario_Registro_Usuario,
        nombre: "registro"
    })
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const [cargando, setCargando] = useState(false)

    const registrarInformacion = () => {
        let ent = {...entidad};
        if (!ent.nombre || !ent.correo || !ent.celular) {
            alert("Nombre, Correo y Celular son necesarios para suscribirte")
            return
        }
        ent.fecha = new Date().getTime()
        setCargando(true)
        guardarDoc("potenciales", ent).then((dox) => {
            if (dox.res) {
                alert("Gracias por registrarte")
                limpiarEntidad()
            } else {
                alert("Ups, sucedio algo mal, intentalo mas tarde")
            }
            setCargando(false)
        })
    }


    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >


            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: "1400px", px: 3}}
            >

                <Grid
                    component={"div"}
                    data-aos="fade-up"
                    data-aos-delay={100}
                    data-aos-duration="1000"
                    item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                    <img src={titulo} width={sCell ? "90%" : "30%"} height={"auto"}/>
                </Grid>

                <Grid
                    component={"div"}
                    data-aos="fade-up"
                    data-aos-delay={150}
                    data-aos-duration="1000"

                    item container lg={8} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 8}}>
                    <Typography sx={{color: "#fff", textAlign: "center", fontSize: masSM ? 20 : 18, fontWeight: 500}}>
                        Periódicamente generamos beneficios adicionales a nuestros clientes.
                        Llena este formulario y recibirás esta información de manera prioritaria.
                    </Typography>
                </Grid>

                <Grid
                    component={"div"}
                    data-aos="fade-up"
                    data-aos-delay={200}
                    data-aos-duration="1000"
                    item lg={8} sm={10} xs={12} sx={{marginTop: 10}}>
                    <Formulario {...props} />
                </Grid>

                <Grid
                    component={"div"}
                    data-aos="fade-up"
                    data-aos-delay={250}
                    data-aos-duration="1000"
                    item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 6}}>
                    <LoadingButton
                        variant={"contained"}
                        loading={cargando}
                        onClick={() => registrarInformacion()}
                        color={"secondary"} sx={{color: COLORPRIMARIO}}>Enviar</LoadingButton>
                </Grid>


            </Grid>

        </Grid>
    )
}

export default MantenteInformado;