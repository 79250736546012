/**************************************************
 * Nombre:       Formulario_Anuncio
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid} from "@mui/material";
import {
    FormBase,
} from "../../Modulo_Formularios/ComponentesBase/C_Forms";
import {subirUnaImagenCroper} from "../../Servicios/Storage/subirUnaImagenCroper";
import {Flag, Message, Whatsapp} from "iconsax-react";
import {LoadingButton} from "@mui/lab";


const Formulario_Blog = ({...props}) => {

    return (
        <FormBase {...props} >
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={4}
                sx={{p: 2}}
            >

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIconSelect label={'Tipo'} Icono={Flag} dato={'tipo'}
                                              opciones={['una imagen', 'dos imagenes']}/>
                </Grid>

                <Grid item lg={7} sm={12} xs={12}>
                    <FormBase.ImagenDimencion dato={"img1"} carpeta={"perfiles"} funcion={subirUnaImagenCroper}
                                              x={props.entidad && props.entidad.tipo === 'dos imagenes' ? 2 : 3}
                                              y={props.entidad && props.entidad.tipo === 'dos imagenes' ? 1 : 2}/>
                </Grid>

                {props.entidad && props.entidad.tipo === 'dos imagenes' &&
                <Grid item lg={7} sm={12} xs={12}>
                    <FormBase.ImagenDimencion dato={"img2"} carpeta={"perfiles"} funcion={subirUnaImagenCroper} x={2}
                                              y={1}/>
                </Grid>
                }


                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon label={'Titulo'} Icono={Message} dato={'titulo'}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon label={'Contenido'} Icono={Message} dato={'contenido'} lineas={10}/>
                </Grid>


            </Grid>
        </FormBase>
    )
}

export default Formulario_Blog;