/**************************************************
 * Nombre:       Dialogo_Detalle_MiniBlog
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useEffect, useState} from 'react';
import {Grid, Typography} from "@mui/material";
import logo from '../../Recursos/logo.svg'

const Dialogo_Detalle_MiniBlog = ({id}) => {
    const [blog, setBlog] = useState({})

    useEffect(() => {

        if (id) {
            setBlog({...id})
        }
    }, [id])
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Grid item lg={12} sm={12} xs={12}>
                {blog.img1 && <img src={blog.img1} width={"100%"} height={"auto"}/>
                }

                {blog.img2 &&
                <img src={blog.img2} width={"100%"} height={"auto"} style={{marginTop: 18}}/>
                }
            </Grid>

            <Grid container item lg={12} sm={12} xs={12} sx={{marginTop: 2, justifyContent: "center"}}>
                <Typography sx={{fontWeight: 800, fontSize: 24, color: "#fff"}}>{blog.titulo}</Typography>
            </Grid>


            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 1}}>
                <Typography sx={{
                    fontSize: 16,
                    fontWeight: 500,
                    color: "#fff"
                }}>{blog.contenido}</Typography>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", m: 2}}>
                <img src={logo} width={"150"}/>
            </Grid>


        </Grid>
    )
}

export default Dialogo_Detalle_MiniBlog;