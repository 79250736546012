/**************************************************
 * Nombre:       CatalogoSeccion
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useEffect, useState} from 'react';
import {Grid, Tab, Tabs, Typography, useMediaQuery} from "@mui/material";
import CeparadorTitulo from "./Componentes/CeparadorTitulo";
import Testimonios from "./Componentes/Testimonios";
import {useParams} from 'react-router-dom'
import {irArriba} from "../../Utilidades/irArriba";
import {theme} from "../../Tema";
import {Splide, SplideSlide, SplideTrack,} from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import Tarjeta_Categoria from "../Tarjetas/Tarjeta_Categoria";
import pollo from "../../Recursos/cat_pollo.png";
import congelados from "../../Recursos/cat_congelados.png";
import proces from "../../Recursos/cat_procesados.png";
import {Helmet} from "react-helmet";


const CatalogoSeccion = () => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const parms = useParams()
    const [categoria, setCategoira] = useState("Procesados")
    const [lista, setlista] = useState([]);


    useEffect(() => {
        irArriba()
        if (parms.id) {
            setCategoira(parms.id)
        } else {
            setCategoira("Procesados")
        }
    }, [parms])

    useEffect(() => {
        switch (categoria) {
            case "Procesados":
                setlista(procesados)
                break
            case "Pollo en Presas":
                setlista(crudos)
                break
            case "Apanados" :
                setlista(apanados)

        }
    }, [categoria])

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Helmet>
                <meta charSet="utf-8"/>
                <title>Pollos Aretama | Catalogo de productos aretama</title>
                <link rel="canonical" href="https://aretama.com/"/>
            </Helmet>

            <Typography component={'h1'} sx={{display: 'none'}}>Productos de Pollo</Typography>
            <Typography component={'h2'} sx={{display: 'none'}}>Venta de pollo crudo</Typography>
            <Typography component={'h2'} sx={{display: 'none'}}>Pollo entero</Typography>
            <Typography component={'h2'} sx={{display: 'none'}}>Pollo al por mayor</Typography>
            <Typography component={'h2'} sx={{display: 'none'}}>Pollo en presas</Typography>
            <Typography component={'h2'} sx={{display: 'none'}}>Pollo crudo</Typography>


            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{maxWidth: "1400px", px: 3}}
            >


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                    <CeparadorTitulo titulo={"CATALOGO"}/>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 8}}>
                    <Tabs value={categoria}
                          indicatorColor={"secondary"}
                          variant={"scrollable"}
                          onChange={(e) => console.log(e)}

                    >
                        {categos.map((item, index) => {
                            return (

                                <Tab
                                    key={index}
                                    label={item.nombre} value={item.nombre}
                                    sx={{maxWidth: 100}}
                                    component={() => {
                                        return (
                                            <Tarjeta_Categoria item={item} setEscogido={setCategoira} index={index}/>
                                        )
                                    }}
                                />


                            )
                        })}


                    </Tabs>
                </Grid>


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: sCell ? 6 : 14}}>
                    <Splide
                        options={{
                            padding: '5rem',
                            perPage: sCell ? 1 : 2,
                            pagination: false,
                            autoplay: true

                        }}
                        hasTrack={false}
                        aria-label="My Favorite Images">
                        <SplideTrack>
                            {lista.map((it, index) => {
                                return (
                                    <SplideSlide>
                                        <Zoom>
                                            <img
                                                src={it}
                                                style={{width: "90%", height: "auto"}}
                                                key={index}/>

                                        </Zoom>
                                    </SplideSlide>
                                )
                            })}

                        </SplideTrack>


                    </Splide>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 8}}>
                    <Testimonios/>
                </Grid>

            </Grid>

        </Grid>
    )
}

export default CatalogoSeccion;

const categos = [
    {
        img: proces,
        nombre: "Procesados"
    },
    {
        img: pollo,
        nombre: "Pollo en Presas"
    },
    {
        img: congelados,
        nombre: "Apanados"
    }

]


const apanados = [
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-12.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-13.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-14.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-15.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-16.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-17.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-18.jpg",
]

const procesados = [
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-19.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-20.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-21.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-22.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-23.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-24.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-25.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-26.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-27.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-28.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-29.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-30.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-31.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-32.jpg",

]

const crudos = [

    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-33.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-34.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-35.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-36.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-37.jpg",

]


const imgs = [
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-1.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-2.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-3.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-4.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-5.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-6.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-7.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-8.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-9.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-10.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-11.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-12.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-13.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-14.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-15.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-16.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-17.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-18.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-19.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-20.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-21.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-22.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-23.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-24.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-25.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-26.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-27.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-28.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-29.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-30.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-31.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-32.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-33.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-34.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-35.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-36.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-37.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-38-38.jpg",
    "https://www.aretama.com/catalogoslide/YDRAY-CATALOGO-2020-39.jpg",


]

