/**************************************************
 * Nombre:       NuestroAliado
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useState} from 'react';
import {Grid, Typography, useMediaQuery} from "@mui/material";
import aliado from '../../../Recursos/tit_nuestroalido.svg'
import beneficios from '../../../Recursos/tit_beneficios.svg'
import circulo from '../../../Recursos/circulo.svg'
import {useFormulario} from "../../../Modulo_Formularios/Hooks/useFormulario";
import Formulario_Registro_Aliando from "../../Formularios/Formulario_Registro_Aliando";
import {COLORPRIMARIO} from "../../../Constantes";
import {theme} from "../../../Tema";
import {guardarDoc} from "../../../Servicios/BD/guardarDoc";
import {LoadingButton} from "@mui/lab";


const NuestroAliado = () => {
    const {Formulario, props, entidad, limpiarEntidad} = useFormulario({
        Form: Formulario_Registro_Aliando,
        nombre: "aliado"
    })
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const [cargando, setCargando] = useState(false)


    const enviarFormulario = () => {
        let ent = {...entidad};
        if (!ent.nombre || !ent.correo || !ent.celular) {
            alert("Nombre, Correo y Celular son necesarios para suscribirte")
            return
        }
        ent.fecha = new Date().getTime()
        setCargando(true)
        guardarDoc("aliados", ent).then((dox) => {
            if (dox.res) {
                alert("Gracias por tu informacion.\n\n" + "Uno de nestros asesores se comunicara contigo y recibirás atencion personalizada")
                limpiarEntidad()
            } else {
                alert("Ups, sucedio algo mal, intentalo mas tarde")
            }
            setCargando(false)
        })
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            component={"div"}
            id={"aliado"}
        >


            <Grid
                component={"div"}
                data-aos="fade-up"
                data-aos-delay={150}
                data-aos-duration="1500"
                item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                <img src={aliado} width={sCell ? 280 : 400} height={"auto"}/>
            </Grid>

            <Grid
                component={"div"}
                data-aos="fade-up"
                data-aos-delay={200}
                data-aos-duration="1500"
                item lg={8} sm={12} xs={12} sx={{marginTop: 6}}>
                <Typography
                    sx={{color: "#fff", fontSize: 18, fontWeight: 500, textAlign: "center"}}
                >Somos provedores de pollo en Bogota y tu negocio pude ser nuestro aliado obteniendo asi beneficios
                    extras.</Typography>
            </Grid>

            <Grid
                component={"div"}
                data-aos="fade-up"
                data-aos-delay={250}
                data-aos-duration="1500"
                item lg={12} sm={12} xs={12} sx={{marginTop: 12}}>
                <img src={beneficios} width={sCell ? 150 : 200} height={"auto"}/>
            </Grid>

            <Grid item lg={11} sm={12} xs={12} sx={{marginTop: 4}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems={sCell ? "flex-start" : "center"}
                    spacing={1}
                >

                    {benes.map((item, index) => {
                        return (
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                data-aos="fade-up"
                                data-aos-offset={0}
                                data-aos-delay={150 * index}
                                data-aos-duration="1500">
                                <Grid item container lg={1} sm={1} xs={1}
                                      sx={{justifyContent: "center", marginTop: sCell ? 1 : 0}}>
                                    <img src={circulo} width={12} height={"auto"}/>
                                </Grid>
                                <Grid item lg={11} sm={11} xs={11}>
                                    <Typography sx={{color: "#fff", fontSize: sCell ? 16 : 18}}>{item}</Typography>
                                </Grid>
                            </Grid>


                        )
                    })}

                </Grid>
            </Grid>

            <Grid
                component={"div"}
                data-aos="fade-up"
                data-aos-delay={250}
                data-aos-duration="1500"
                item lg={8} sm={10} xs={12} sx={{marginTop: 8}}>
                <Formulario {...props} />
            </Grid>

            <Grid
                component={"div"}
                data-aos="fade-up"
                data-aos-delay={300}
                data-aos-duration="1500"
                data-aos-offset={0}
                item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 6}}>
                <LoadingButton
                    variant={"contained"}
                    loading={cargando}
                    onClick={() => enviarFormulario()}
                    color={"secondary"} sx={{color: COLORPRIMARIO}}>Enviar</LoadingButton>
            </Grid>


        </Grid>
    )
}

export default NuestroAliado;

const benes = [
    "Productos de frescura garantizada",
    "Disponibilidad de los productos a todo momento",
    "Precio más competitivo",
    "Rapidez en la entrega",
    "Servicio al cliente",
    "Trazabilidad y garantía del producto"
]