/**************************************************
 * Nombre:       Formulario_Registro_Usuario
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid} from "@mui/material";
import {FormBase, InputCheck} from "../../Modulo_Formularios/ComponentesBase/C_Forms";
import {Google, Message, Mobile, Tag2, User} from "iconsax-react";


const Formulario_Pqrs = ({...props}) => {

    return (
        <FormBase {...props} >
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={4}
            >


                <Grid item lg={6} sm={6} xs={12}>
                    <FormBase.InputIcon dato={"nombre"} label={"Nombre"}
                                        Icono={User}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={12}>
                    <FormBase.InputIcon dato={"correo"} label={"Correo"}
                                        Icono={Google}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={12}>
                    <FormBase.InputIcon dato={"celular"} label={"Celular"}
                                        Icono={Mobile}/>
                </Grid>


                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon dato={"mensaje"} lineas={8} label={"Mensaje (opcional)"}
                                        Icono={Message}/>
                </Grid>

                <Grid item lg={3} sm={4} xs={6}>
                    <FormBase.InputCheck label={"Peticiones"} dato={"peticion"}/>
                </Grid>

                <Grid item lg={3} sm={4} xs={6}>
                    <FormBase.InputCheck label={"Reclamo"} dato={"reclamo"}/>
                </Grid>

                <Grid item lg={3} sm={4} xs={6}>
                    <FormBase.InputCheck label={"Sugerencia"} dato={"sugerencia"}/>
                </Grid>



            </Grid>

        </FormBase>
    )
}

export default Formulario_Pqrs;