import {Button, Grid, Menu, MenuItem, Typography} from "@mui/material";
import {COLORPRIMARIO} from "../../Constantes";
import {useRef, useState} from "react";

export const useBotonChat = ({titulo = "Hablar con un asesor"}) => {
    const [open, setOpen] = useState(false)
    const [anchor, setAnchor] = useState(null)
    const ref = useRef()

    const abrir = (e) => {
        setAnchor(e.currentTarget)
        setOpen(true)

    }

    const cerrar = () => {
        setOpen(false)
        setAnchor(null)
    }

    const Boton = () => {
        return (
            <>

                <Button
                    ref={ref}
                    onClick={(e) => abrir(e)}
                    color={"secondary"}
                    sx={{color: COLORPRIMARIO}}
                >{titulo}</Button>

                <Menu
                    id="basic-menu"
                    open={open}
                    anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                    onClose={cerrar}
                >

                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                            <Typography>Asesores Disponibles</Typography>
                        </Grid>

                        <Grid item lg={12} sm={12} xs={12}>
                            {asesores.map((it) => {
                                return (
                                    <MenuItem onClick={() => alert(it)}>Profile</MenuItem>
                                )
                            })}
                        </Grid>

                    </Grid>


                </Menu>
            </>

        )
    }

    return {
        Boton,

    }
}

const asesores = [1, 2, 3]