/**************************************************
 * Nombre:       Anuncio
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useState, useEffect} from 'react';
import {Button, Grid, Typography} from "@mui/material";
import {Ho_ManejoTargetas} from "../../Modulo_Tarjetas/Hocs/Ho_ManejoTargetas";
import Tarjeta_Asesores from "../Tarjetas/Tarjeta_Asesores";
import useRecuperarColleccionListeningToda from "../../Servicios/BD/useRecuperarColleccionListeningToda";
import {useDialogo} from "../../Modulo_Dialgos/Hooks/useDialog";
import Detalle_Asesor from "../DetalleSeccion/Detalle_Asesor";


const Asesores = () => {
    const {Dialogo, abrir} = useDialogo({Componente: Detalle_Asesor})
    const [wheres, setWheres] = useState([])
    const {data} = useRecuperarColleccionListeningToda({col: 'asesores', wheres: wheres, orden: 'nombre'})


    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Dialogo/>

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{maxWidth: "1400px", py: 2}}
            >


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "flex-end", marginTop: 2}}>
                    <Button
                        onClick={() => abrir()}
                        color={'secondary'}>Agregar Asesor</Button>
                </Grid>


                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 6}}>
                    <Ho_ManejoTargetas Card={Tarjeta_Asesores} data={data} lg={6} click={abrir}/>
                </Grid>

            </Grid>


        </Grid>
    )
}

export default Asesores;