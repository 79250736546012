/**************************************************
 * Nombre:       Tarjeta_Asesores
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Avatar, ButtonBase, Grid} from "@mui/material";
import {CardBase, Imagen} from "../../Modulo_Tarjetas/Componentes/C_Cards";
import {Call, Flag, Message, Sms, Trash, User, Whatsapp} from "iconsax-react";
import {borrarDoc} from "../../Servicios/BD/borrarDoc";


const Tarjeta_Potenciales = ({valores, click, difiere = false}) => {


    return (

        <CardBase valores={valores}>

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
            >

                <Grid item lg={3} sm={3} xs={6}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        <Grid item lg={12} sm={12} xs={12}>
                            <CardBase.FechaComponente dato1={'fecha'}>
                                <CardBase.IconTexto Icono={User} dato1={'nombre'}/>
                            </CardBase.FechaComponente>
                        </Grid>


                    </Grid>
                </Grid>


                <Grid item lg={3} sm={3} xs={6}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >

                        {difiere ?
                            <Grid item lg={12} sm={12} xs={12}>
                                <CardBase.IconTexto Icono={Sms} dato1={'correo'}/>
                            </Grid>
                            : <Grid item lg={12} sm={12} xs={12}>
                                <CardBase.IconTexto Icono={Flag} dato1={'tipo'}/>
                            </Grid>
                        }

                        <Grid item lg={12} sm={12} xs={12}>
                            <CardBase.IconTexto Icono={Call} dato1={'celular'}/>
                        </Grid>

                    </Grid>
                </Grid>


                <Grid item lg={5} sm={5} xs={12}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={1}
                    >

                        {!difiere &&
                        <Grid item lg={12} sm={12} xs={12}>
                            <CardBase.IconTexto Icono={Sms} dato1={'correo'}/>
                        </Grid>
                        }

                        <Grid item lg={12} sm={12} xs={12}>
                            <CardBase.IconTextoParrafo Icono={Message} dato1={'mensaje'} lineas={4}
                                                       titulo={'Mensaje:'}/>
                        </Grid>

                    </Grid>
                </Grid>

                <Grid item container lg={1} sm={1} xs={12} sx={{justifyContent: "flex-end"}}>
                    <ButtonBase
                        onClick={() => click(valores)}
                        sx={{p: 0.5, borderRadius: 2}}><Trash variant={'Bold'}/></ButtonBase>
                </Grid>


            </Grid>

        </CardBase>

    )
}

export default Tarjeta_Potenciales;