/**************************************************
 * Nombre:       Categorias
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {ButtonBase, Grid, Typography, useMediaQuery} from "@mui/material";
import pollo from '../../../Recursos/cat_pollo.png'
import crudos from '../../../Recursos/cat_crudos.png'
import congelados from '../../../Recursos/cat_congelados.png'
import procesados from '../../../Recursos/cat_procesados.png'
import {theme} from "../../../Tema";
import {useNavigate} from 'react-router-dom';
import {marcarEvento} from "../../../Servicios/Analiticas/marcarEvento";


const Categoria = ({item}) => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const navigate = useNavigate()

    return (
        <ButtonBase
            onClick={() => {
               // navigate("/Productos/" + item.nombre)}
                marcarEvento("categoria_home_" + item.nombre)
                navigate("/Catalogo")

            }}

            variant={"nosombra"} sx={{width: "100%"}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"

            >

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                    <img src={item.img} width={"auto"} height={sCell ? 80 : 110}/>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                    <Typography sx={{
                        color: "#fff",
                        fontSize: sCell ? 18 : 22,
                        fontWeight: sCell ? 600 : 600
                    }}>{item.nombre}</Typography>
                </Grid>

            </Grid>
        </ButtonBase>
    )
}


const Categorias = () => {

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: "1400px", px: 3}}
                spacing={2}

            >

                {categos.map((catego, index) => {
                    return (
                        <Grid
                            key={index}
                            component={"div"}
                            data-aos="fade-up"
                            data-aos-delay={150 * index}
                            data-aos-duration="1000"
                            item container lg={3} sm={3} xs={6} sx={{justifyContent: "center"}}>
                            <Categoria item={catego}/>
                        </Grid>

                    )
                })}

            </Grid>


        </Grid>
    )
}

export default Categorias;

const categos = [
    {
        img: procesados,
        nombre: "Procesados"
    },
    {
        img: pollo,
        nombre: "Pollo en Presas"
    },
    {
        img: congelados,
        nombre: "Apanados"
    }

]