/**************************************************
 * Nombre:       Footer
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {ButtonBase, Grid, Typography, useMediaQuery} from "@mui/material";
import {COLORACENTO, redes, secciones} from "../Constantes";
import {useNavigate} from 'react-router-dom'
import escaparate from '../Recursos/escaparate.png'
import logo from '../Recursos/logo.svg'
import {irUrl} from "../Utilidades/irUrl";
import {theme} from "../Tema";
import {useDialogo} from "../Modulo_Dialgos/Hooks/useDialog";
import Login from "./Login/Login";


const Footer = () => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const navigate = useNavigate()
    const {Dialogo, abrir} = useDialogo({Componente: Login})

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{borderTop: 2, borderColor: COLORACENTO, marginTop: 10}}

        >

            <Dialogo/>


            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{maxWidth: "1400px", px: sCell ? 4 : 8, py: 6}}
            >

                <Grid item lg={3} sm={3} xs={6}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >

                        <Grid item lg={12} sm={12} xs={12}>
                            <Typography sx={{color: COLORACENTO, fontWeight: 600, fontSize: 18}}>Links
                                Rapidos</Typography>
                        </Grid>

                        <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 2}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >

                                {secciones.map((seccion) => {
                                    return (
                                        <Grid item lg={12} sm={12} xs={12}>
                                            <ButtonBase
                                                onClick={() => navigate("/" + seccion.nombre)}
                                            >
                                                <Typography
                                                    sx={{color: "#fff", fontWeight: 500}}>{seccion.nombre}</Typography>
                                            </ButtonBase>
                                        </Grid>
                                    )
                                })}

                                <Grid item lg={12} sm={12} xs={12}>
                                    <ButtonBase
                                        onClick={() => abrir()}
                                    >
                                        <Typography
                                            sx={{color: "#fff", fontWeight: 500}}>{"Administracion"}</Typography>
                                    </ButtonBase>
                                </Grid>

                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>

                <Grid item lg={3} sm={4} xs={6}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >

                        <Grid item lg={12} sm={12} xs={12}>
                            <Typography sx={{color: COLORACENTO, fontWeight: 600, fontSize: 18}}>Categorias</Typography>
                        </Grid>

                        <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 2}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >

                                {categorias.map((categoria) => {
                                    return (
                                        <Grid item lg={12} sm={12} xs={12}>
                                            <ButtonBase
                                                onClick={() => navigate("/Catalogo/" + categoria)}
                                            >
                                                <Typography
                                                    sx={{color: "#fff", fontWeight: 500}}>{categoria}</Typography>
                                            </ButtonBase>
                                        </Grid>
                                    )
                                })}

                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>

                <Grid item lg={4} sm={5} xs={12} sx={{marginTop: sCell ? 2 : 0}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >

                        <Grid item lg={12} sm={12} xs={12}>
                            <Typography sx={{color: COLORACENTO, fontWeight: 600, fontSize: 18}}>Contactos</Typography>
                        </Grid>

                        <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 2}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >

                                {direcciones.map((direccion) => {
                                    return (
                                        <Grid item lg={12} sm={12} xs={12}>
                                            <ButtonBase
                                                // onClick={() => navigate("/" + seccion.nombre)}
                                            >
                                                <Typography
                                                    sx={{
                                                        color: "#fff",
                                                        fontWeight: 800
                                                    }}>{direccion.nombre}
                                                    <span style={{fontWeight: 500}}>{": " + direccion.dir}</span>
                                                </Typography>
                                            </ButtonBase>
                                        </Grid>
                                    )
                                })}

                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>

                <Grid item lg={2} sm={12} xs={12} sx={{marginTop: masSM ? 0 : 4}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                    >

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                            <img src={escaparate} width={180} height={"auto"}/>
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                            <img src={logo} width={160} height={"auto"}/>
                        </Grid>

                        <Grid item container lg={8} sm={2} xs={12} sx={{marginTop: 1, justifyContent: "center"}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent={masSM ? "flex-start" : "center"}
                                alignItems="flex-start"
                            >
                                {redes.map((red, index) => {
                                    return (
                                        <Grid item container lg={4} sm={4} xs={2} sx={{justifyContent: "center"}}
                                              key={index}>
                                            <ButtonBase
                                                variant={"sombra"}
                                                onClick={() => irUrl(red.url)}
                                            >
                                                {red.img}
                                            </ButtonBase>
                                        </Grid>
                                    )
                                })}


                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>

            </Grid>

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{backgroundColor: "#1C1C1C", p: 1}}
            >

                <Typography sx={{color: "#fff"}}>© Copyright 2023 - 2024 | Aretama</Typography>

            </Grid>


        </Grid>
    )
}

export default Footer;


const categorias = ["Procesados", "Pollo en Presas", "Apanados"]

const direcciones = [
    {
        nombre: "PUNTO GRANJAS",
        dir: "Cra. 69 #16-70",
        url: ""
    }, {
        nombre: "PUNTO 53",
        dir: "Av. Caracas #52-91",
        url: ""
    }, {
        nombre: "CORREO",
        dir: "info@aretama.com",
        url: ""
    }, {
        nombre: "WhatsApp",
        dir: "+57 316 316 1710",
        url: ""
    }]


