/**************************************************
 * Nombre:       Blog
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useState} from 'react';
import {Button, Grid} from "@mui/material";
import {Ho_ManejoTargetas} from "../../Modulo_Tarjetas/Hocs/Ho_ManejoTargetas";
import {useDialogo} from "../../Modulo_Dialgos/Hooks/useDialog";
import useRecuperarColleccionListeningToda from "../../Servicios/BD/useRecuperarColleccionListeningToda";
import Detalle_Blog from "../DetalleSeccion/Detalle_Blog";
import Tarjeta_Blog from "../Tarjetas/Tarjeta_Blog";


const Blog = () => {
    const {Dialogo, abrir} = useDialogo({Componente: Detalle_Blog})
    const [wheres, setWheres] = useState([])
    const {data} = useRecuperarColleccionListeningToda({col: 'blogs', wheres: wheres, orden: 'titulo'})

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Dialogo/>

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: "1400px", py: 2}}>


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "flex-end", marginTop: 2}}>
                    <Button
                        onClick={() => abrir()}
                        color={'secondary'}>Agregar Blog</Button>
                </Grid>


                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 6}}>
                    <Ho_ManejoTargetas Card={Tarjeta_Blog} data={data} click={abrir}/>
                </Grid>

            </Grid>

        </Grid>
    )
}

export default Blog;