// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {getAuth} from 'firebase/auth'
import {getFirestore} from 'firebase/firestore'
import {getStorage} from 'firebase/storage'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCSgZFMWg0UzFiw2V20y5g0-j-KSClilsw",
    authDomain: "pagina-web-6e0e2.firebaseapp.com",
    projectId: "pagina-web-6e0e2",
    storageBucket: "pagina-web-6e0e2.appspot.com",
    messagingSenderId: "202691378438",
    appId: "1:202691378438:web:c816eeabaade4fc53cf287",
    measurementId: "G-J0SRSWM4BH"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

export const auth = getAuth(app)
export const fire = getFirestore(app)
export const storage = getStorage(app)