/*******************************************************
 * Nombre: ComponentesBase Formulario
 * Descripcion : Contiene el template de todos los
 *               componentes que van en un formulario,
 * estos se conectan por medio de un FormularioBase
 *
 * Libreirias: Mui.com, @mui/system, @mui/lab, iconsax-react,
 *             react-number-format, react-datepicker
 *
 * Autor: Luis Rosero
 * Tiempo: 10 hrs
 *******************************************************/
import {createContext, createRef, forwardRef, useContext, useEffect, useState} from "react";
import {
    Button,
    ButtonBase,
    Checkbox,
    CircularProgress,
    Dialog,
    Divider,
    FormControlLabel,
    Grid,
    InputAdornment,
    MenuItem,
    TextField,
    Typography
} from "@mui/material";
import {Component, DollarCircle, Gallery, Trash} from "iconsax-react";
import NumberFormat from "react-number-format";
import {COLORACENTO, COLORPRIMARIO} from "../../Constantes";
import imageCompression from "browser-image-compression";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

const formContext = createContext()

const PRIMARIO = "#161615"

export const FormBase = (props) => {
    const {
        entidad,
        cambio,
        click,
        setDato,
        children,
        cargando,
    } = props


    const {Provider} = formContext;

    const valueProvider = {
        entidad,
        cambio,
        click,
        setDato,
        cargando
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"

        >

            <Provider value={valueProvider}>

                {children}
            </Provider>

        </Grid>

    )
}

export const InputIcon = ({
                              Icono = Component,
                              label,
                              dato,
                              lineas = 1,
                              type = "text",
                              editable = true,
                              size = "small"
                          }) => {
    const cData = useContext(formContext)

    const getAltura = (lineas) => {

        switch (lineas) {
            case 2:
                return -lineas * 14
            case 3:
                return -lineas * 16
            case 4:
                return -lineas * 18
            case 5:
                return -lineas * 19.5
            case 6:
                return -lineas * 20
            case 7:
                return -lineas * 20
            case 8:
                return -lineas * 20.5
            case 9:
                return -lineas * 21
            case 10:
                return -lineas * 21.5
        }

    }

    return (
        <TextField label={label}
                   name={dato}
                   value={cData.entidad[dato] || ''}
                   multiline={lineas > 1} rows={lineas}
                   onChange={cData.cambio}
                   disabled={!editable}
                   type={type}
                   size={size}
                   color={"secondary"}
                   focused={true}
                   InputProps={{
                       startAdornment: (
                           <InputAdornment position="start">
                               <Icono color={COLORACENTO} variant={"Bold"}
                                      style={{marginTop: lineas > 1 ? getAltura(lineas) : 0}}/>
                           </InputAdornment>
                       ),

                   }}
        />
    )
}

export const Input = ({

                          label,
                          dato,
                          lineas = 1,
                          type = "text",
                          editable = true,
                          size = "small"
                      }) => {
    const cData = useContext(formContext)

    const getAltura = (lineas) => {

        switch (lineas) {
            case 2:
                return -lineas * 14
            case 3:
                return -lineas * 16
            case 4:
                return -lineas * 18
            case 5:
                return -lineas * 19.5
            case 6:
                return -lineas * 20
            case 7:
                return -lineas * 20
            case 8:
                return -lineas * 20.5
            case 9:
                return -lineas * 21
            case 10:
                return -lineas * 21.5
        }

    }

    return (
        <TextField label={label}
                   name={dato}
                   value={cData.entidad[dato] || ''}
                   multiline={lineas > 1} rows={lineas}
                   onChange={cData.cambio}
                   disabled={!editable}
                   type={type}
                   size={size}

        />
    )
}

export const BotonGuardar = ({children, dato = "submit"}) => {
    const cData = useContext(formContext)

    return (
        <Button variant={"contained"} name={dato} color={"primary"} sx={{color: "#3d3d3d"}}
                onClick={(e) => cData.click(e.target.name)}>
            {children}
        </Button>
    )
}

export const InputCheck = ({label, dato, editable = true}) => {
    const cData = useContext(formContext)
    const [valor, setValor] = useState(false)

    const cambio = (date) => {
        cData.setDato(dato, date.target.checked)
        setValor(!valor)
    }

    useEffect(() => {

        if (cData.entidad && cData.entidad[dato]) {
            let val = cData.entidad[dato] ? cData.entidad[dato] : false
            setValor(val)
        }


    }, [cData.entidad && cData.entidad[dato]])

    return (
        <FormControlLabel
            control={<Checkbox
                color={"secondary"}
                sx={{
                    color: COLORACENTO,
                    '&.Mui-checked': {
                        color: COLORACENTO,
                    },
                }}
                value={valor}
                checked={valor}
                onChange={cambio}
                disabled={!editable}/>}
            componentsProps={{typography: {fontSize: 16, color: COLORACENTO, fontWeight: 600}}}
            label={label}/>
    )
}

export const InputIconSelect = ({Icono = Component, label, dato, editable = true, opciones = [], size = "small"}) => {
    const cData = useContext(formContext)
    return (
        <TextField label={label} name={dato} value={cData.entidad[dato] || ''}
                   select
                   onChange={cData.cambio}
                   disabled={!editable}
                   size={size}
                   color={"secondary"}
                   focused
                   InputProps={{
                       startAdornment: (
                           <InputAdornment position="start">
                               <Icono color={COLORACENTO} variant={"Bold"}/>
                           </InputAdornment>
                       ),

                   }}
        >
            {opciones.map((item) => {
                return (
                    <MenuItem key={item} value={item}>
                        {item}
                    </MenuItem>
                )
            })}

        </TextField>
    )
}

const NumberFormatCustom = forwardRef(({onChange, ...other}, ref) => (


    <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={values => {
            onChange({
                target: {
                    name: other.name,
                    value: values.value
                }
            });
        }}
        thousandSeparator
    />


));

export const InputIconMoneda = ({Icono = DollarCircle, label, dato, editable = true, size = "small"}) => {
    const cData = useContext(formContext)
    const [value, setValue] = useState(cData.entidad[dato] ? cData.entidad[dato] : '')

    const handleChange = event => {
        setValue(parseInt(event.target.value));
        cData.cambio(event);
    };


    return (
        <TextField
            disabled={!editable}
            label={label}
            value={value}
            onChange={handleChange}
            name={dato}
            size={size}
            id="formatted-numberformat-input"
            InputProps={{
                inputComponent: NumberFormatCustom,
                startAdornment: (
                    <InputAdornment position="start">
                        <Icono color={editable ? PRIMARIO : "#00000060"} variant={"Bold"}/>
                    </InputAdornment>
                ),
            }}
        />


    )
}

export const ImagenCustom = ({
                                 dato,
                                 size = "100%",
                                 funcion,
                                 carpeta = "default",
                                 Icono = Gallery
                                 , colorIcono = "#3d3d3d"
                             }) => {
    const cData = useContext(formContext)

    const hiddenFileInput = createRef(null);
    const [cargando, setCargando] = useState(false);
    const [open, setOpen] = useState(false);
    const [image, setImage] = useState('');
    const [cropper, setCropper] = useState("");


    const abrir = () => {
        setOpen(true)
    }

    const cerrar = () => {
        setOpen(false)

    }

    const clickBoton = (e) => {
        e.preventDefault();

        hiddenFileInput.current.click();
    };

    const onChange = (e) => {
        if (!cargando) {

            let files;
            const validImageTypes = ["image/jpeg", "image/png",];
            if (e.dataTransfer) {
                files = e.dataTransfer.files;
            } else if (e.target) {
                files = e.target.files;
            }

            if (files[0] !== undefined) {
                const fileType = files[0]["type"];
                if (validImageTypes.includes(fileType)) {
                    const reader = new FileReader();
                    reader.onload = () => {
                        setImage(reader.result);
                        abrir()
                    };
                    reader.readAsDataURL(files[0]);
                } else {
                    alert("formato de archivo no soportado");

                }
            }
        } else {
            alert("Hay una imagen procesandoce ahora mismo");

        }
    };

    async function comprimir(url) {

        const imageFile = url;

        const options = {
            maxSizeMB: 5,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        }
        try {
            const compressedFile = await imageCompression(imageFile, options);


            await subir(compressedFile); // write your own logic
        } catch (error) {
            console.log(error);
        }

    }

    const getCropData = () => {
        if (typeof cropper !== "undefined") {
            setCargando(true);
            cerrar()

            cropper.getCroppedCanvas().toBlob(function (blob) {
                comprimir(blob);
                subir(blob)
            });

        }
    };


    const subir = (crop) => {
        setCargando(true)
        funcion(crop, carpeta).then((docs) => {
            if (docs.res) {
                cData.setDato(dato, docs.data)
            }
            setCargando(false)
        })

    };


    return (
        <>
            <input
                type="file"
                onChange={onChange}
                ref={hiddenFileInput}
                style={{display: "none"}}/>
            <ButtonBase sx={{width: size, borderRadius: "10%",}} onClick={(e) => clickBoton(e)}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >

                    <Grid item lg={12} sm={12} xs={12}>
                        {cData && cData.entidad[dato] ?
                            <img src={cData && cData.entidad[dato]} width={"100%"}
                                 style={{borderRadius: 8, marginBottom: -8}}/> :
                            <Icono size={"100%"} variant={"Bulk"} color={colorIcono}/>}
                    </Grid>

                    <Grid item lg={12} sm={12} xs={12} sx={{marginTop: "-70%"}}>
                        {cargando &&
                        <CircularProgress/>
                        }
                    </Grid>

                </Grid>


            </ButtonBase>

            <Dialog open={open} fullWidth maxWidth={"xs"} onClose={cerrar}>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    sx={{padding: 2}}
                >
                    <Grid item container sx={{justifyContent: "center"}}>


                        <Cropper
                            style={{
                                height: 300,
                                width: 300,
                                marginTop: 20,
                                marginBottom: 20,
                            }}
                            src={image}
                            viewMode={2}
                            guides={true}
                            minCropBoxHeight={10}
                            minCropBoxWidth={10}
                            background={true}
                            responsive={true}
                            autoCropArea={0.5}
                            modal={false}
                            checkOrientation={false}
                            onInitialized={(instance) => {
                                setCropper(instance);
                            }}
                        />

                    </Grid>

                    <Grid item container sx={{justifyContent: "center"}}>


                        <Button variant={"contained"} color={"secondary"} size={"small"}
                                onClick={() => getCropData()}
                        >cortar y Subir</Button>
                    </Grid>

                </Grid>

            </Dialog>
        </>

    )
}

export const ImagenDimencion = ({
                                    dato,
                                    size = "100%",
                                    funcion,
                                    carpeta = "default",
                                    Icono = Gallery
                                    , colorIcono = "#3d3d3d",
                                    x = 1,
                                    y = 1
                                }) => {
    const cData = useContext(formContext)

    const hiddenFileInput = createRef(null);
    const [cargando, setCargando] = useState(false);
    const [open, setOpen] = useState(false);
    const [image, setImage] = useState('');
    const [cropper, setCropper] = useState("");


    const abrir = () => {
        setOpen(true)
    }

    const cerrar = () => {
        setOpen(false)

    }

    const clickBoton = (e) => {
        e.preventDefault();

        hiddenFileInput.current.click();
    };

    const onChange = (e) => {
        if (!cargando) {

            let files;
            const validImageTypes = ["image/jpeg", "image/png",];
            if (e.dataTransfer) {
                files = e.dataTransfer.files;
            } else if (e.target) {
                files = e.target.files;
            }

            if (files[0] !== undefined) {
                const fileType = files[0]["type"];
                if (validImageTypes.includes(fileType)) {
                    const reader = new FileReader();
                    reader.onload = () => {
                        setImage(reader.result);
                        abrir()
                    };
                    reader.readAsDataURL(files[0]);
                } else {
                    alert("formato de archivo no soportado");

                }
            }
        } else {
            alert("Hay una imagen procesandoce ahora mismo");

        }
    };

    async function comprimir(url) {

        const imageFile = url;

        const options = {
            maxSizeMB: 5,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        }
        try {
            const compressedFile = await imageCompression(imageFile, options);


            await subir(compressedFile); // write your own logic
        } catch (error) {
            console.log(error);
        }

    }

    const getCropData = () => {
        if (typeof cropper !== "undefined") {
            setCargando(true);
            cerrar()

            cropper.getCroppedCanvas().toBlob(function (blob) {
                comprimir(blob);
                subir(blob)
            });

        }
    };


    const subir = (crop) => {
        setCargando(true)
        funcion(crop, carpeta).then((docs) => {
            if (docs.res) {
                cData.setDato(dato, docs.data)
            }
            setCargando(false)
        })

    };


    return (
        <>
            <input
                type="file"
                onChange={onChange}
                ref={hiddenFileInput}
                style={{display: "none"}}/>
            <ButtonBase sx={{width: size, borderRadius: "10%",}} onClick={(e) => clickBoton(e)}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >

                    <Grid item lg={12} sm={12} xs={12}>
                        {cData && cData.entidad[dato] ?
                            <img src={cData && cData.entidad[dato]} width={"100%"}
                                 style={{borderRadius: 8, marginBottom: -8}}/> :
                            <Icono size={"100%"} variant={"Bulk"} color={colorIcono}/>}
                    </Grid>

                    <Grid item lg={12} sm={12} xs={12} sx={{marginTop: "-70%"}}>
                        {cargando &&
                        <CircularProgress/>
                        }
                    </Grid>

                </Grid>


            </ButtonBase>

            <Dialog open={open} fullWidth maxWidth={"xs"} onClose={cerrar}>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    sx={{padding: 2}}
                >
                    <Grid item container sx={{justifyContent: "center"}}>


                        <Cropper
                            style={{
                                height: 300,
                                width: 300,
                                marginTop: 20,
                                marginBottom: 20,
                            }}
                            src={image}
                            viewMode={2}
                            guides={true}
                            minCropBoxHeight={10}
                            minCropBoxWidth={10}
                            background={true}
                            responsive={true}
                            autoCropArea={0.5}
                            modal={false}
                            checkOrientation={false}
                            onInitialized={(instance) => {
                                setCropper(instance);
                            }}
                            aspectRatio={x / y}

                        />

                    </Grid>

                    <Grid item container sx={{justifyContent: "center"}}>


                        <Button variant={"contained"} color={"secondary"} size={"small"}
                                onClick={() => getCropData()}
                        >cortar y Subir</Button>
                    </Grid>

                </Grid>

            </Dialog>
        </>

    )
}

export const InputListaSelect = ({
                                     Icono = Component, label, dato, editable = true,
                                     opciones = [], size = "small"
                                 }) => {
    const cData = useContext(formContext)
    const [valores, setValores] = useState([])

    const adicionar = (e) => {

        setValores((arr) => arr.concat(e.replaceAll(" ", "_")))

    }

    const borrar = (e) => {

        let arr = []
        for (let i = 0; i < valores.length; i++) {
            if (e !== valores[i]) {
                arr.push(valores[i])
            }
        }
        setValores(arr)
    }


    useEffect(() => {
        cData.setDato(dato, valores)
    }, [valores])

    useEffect(() => {

        if (cData.entidad[dato] && cData.entidad[dato] !== valores) {
            setValores(cData.entidad[dato])
        }

    }, [cData.entidad[dato]])

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
        >

            <Grid item lg={12} sm={10} xs={10}>
                <TextField label={label} name={dato}
                           select
                           size={size}
                           onChange={(e) => adicionar(e.target.value)}
                           disabled={!editable}
                           InputProps={{
                               startAdornment: (
                                   <InputAdornment position="start">
                                       <Icono color={editable ? PRIMARIO : "#00000060"} variant={"Bold"}/>
                                   </InputAdornment>
                               ),

                           }}
                >
                    {opciones.map((item) => {
                        return (
                            <MenuItem key={item} value={item}>
                                {item}
                            </MenuItem>
                        )
                    })}

                </TextField>
            </Grid>


            <Grid item lg={12} sm={12} xs={12}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >

                    {valores.map((item, index) => {
                        return (
                            <Grid item lg={12} sm={12} xs={12} key={index}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >

                                    <Grid item lg={10} sm={12} xs={12}>
                                        <Typography sx={{color: "#000"}}>{"• " + item}</Typography>
                                    </Grid>

                                    <Grid item container lg={2} sm={12} xs={12} sx={{justifyContent: "flex-end"}}>
                                        <ButtonBase sx={{p: 0.5}} onClick={() => borrar(item)}>
                                            <Trash variant={"Bold"}/>
                                        </ButtonBase>
                                    </Grid>

                                </Grid>

                                <Grid item lg={12} sm={12} xs={12}>
                                    <Divider/>
                                </Grid>
                            </Grid>
                        )
                    })}

                </Grid>

            </Grid>


        </Grid>

    )
}


FormBase.InputIconSelect = InputIconSelect;
FormBase.InputListaSelect = InputListaSelect;
FormBase.InputIconMoneda = InputIconMoneda;
FormBase.Input = Input;
FormBase.InputIcon = InputIcon;
FormBase.InputCheck = InputCheck;
FormBase.BotonGuardar = BotonGuardar;

FormBase.ImagenCustom = ImagenCustom;
FormBase.ImagenDimencion = ImagenDimencion;




